import styled from "styled-components";

export const StyledLogin = styled.div`
    box-sizing: border-box;
    display:flex;
    width:100%;


    .LoginSide{
        width:50%;
        height:100vh;
        overflow:hidden;
    }

    .SliderSide{
        width:50%;
        height:100vh;
        overflow:hidden;
        background-color:#1B3A1B;
    }

    .loginNav{
        width: 100%;
        height: 58px;
        background: #FFFFFF;
        border: 1px solid rgba(197, 194, 194, 0.8);
        display:flex;
        align-items: center;
        padding-left:20px;
       
    }
    .margin-l-2{
        margin-left:10px;
    }

    .c-lg{
        color: #A6CE34;
    }
    .c-lb{
        color: #706E6E;
    }
  
    
`