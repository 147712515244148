import React, { useEffect, useState } from "react";
import axios from 'axios'

import { StyledFullCard } from './Styles/FullCard.styles';

import Table from 'react-bootstrap/Table';


const FullCards = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

    useEffect(() => {
        let token = localStorage.getItem("token");

        const fetchData = async () => {
            setLoading(true);

            let url = `${process.env.REACT_APP_BASE_URL}/api/getrecentcenters`;

            try {
                const { data: response } = await axios.get(url);
                setData(response.centers);
                // console.log(response)
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);
    return (
        <StyledFullCard>
            <div className='title'>Recent Centers Registered</div>
            <div className="table">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Center Name</th>
                            <th>Center Code</th>
                            <th>Center Email</th>
                            {/* <th>Address</th> */}
                            <th>Type</th>
                            <th>Registered Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.length > 0 && (
                                data.map((row, key) => (
                                    <>
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.uniq_id}</td>
                                            <td>{row.email}</td>
                                            {/* <td>{row.address}</td> */}
                                            <td>{row.type}</td>
                                            <td>{row.created_at}</td>
                                        </tr>
                                    </>
                                ))
                            )
                        }

                    </tbody>
                </table>
            </div>
        </StyledFullCard>
    );
}

export default FullCards;
