import styled from "styled-components";

export const StyledForgotPassword = styled.a`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Space+Grotesk:wght@300;400&display=swap');
    
    
    font-family: 'Space Grotesk', sans-serif;
    display: flex;
    max-height: 80px;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
    a {
        font-size: 19px;
        margin-left: auto;
        margin-right: 20px;
        text-decoration:  none;
        color: rgba(10, 119, 12, 1);
        font-weight: 600;
    }
    
`