import { Eject } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { CSVDownload, CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import CourseViewIVA from '../Components/CourseViewIVA';
import SideNavIva from '../Components/IvaDashboards/Navigations/sideNav';
import TopNav from '../Components/IvaDashboards/Navigations/topNav';
import TopPills from '../Components/IvaDashboards/Navigations/topPills';

function CourseView() {
    const { id } = useParams();
    const [searchResult, setSearchResult] = useState('')
    const [course, setCourseData] = useState('')
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/getregstudentcourse/${id}`, requestOptions)
            .then(response => response.json())
            .then(result =>
                setSearchResult(result),
                // setCourseData(result.course)

            )
            .catch(error => console.log('error', error));
    }, [])
    function exportData(e) {
        e.preventDefault();

        console.log('clicked')
    }
    return (
        <>
            <div className='mainbody'>
                <div className='row justify-content-center'>
                    <div className='col-1 navbarIVa text-center py-5 shadow '>
                        <SideNavIva />
                    </div>
                    <div className='col-11'>
                        <div className='d-flex navheader mb-5 justify-content-between align-items-center p-3 shadow'>
                            <TopNav />
                        </div>
                        <div className='container'>
                            <CourseViewIVA />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CourseView
