import React, { useEffect, useState } from 'react'
import PdoIndicators from './PdoIndicators'


export default function Summary_Pdo() {

    const [pdoNames, setPdoName] = useState('')

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/allpdo`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setPdoName(result)
                localStorage.setItem('pdoArray', JSON.stringify(result))
            })
            .catch(error => console.log('error', error));
    }, []);

    return (
        <div className='p-3'>

            {Array.isArray(pdoNames) && pdoNames.map((pdo, pdoindex) => {
                return (
                    <div>
                        <h4 className='bg-success text-white p-3'>{pdo.title}</h4>
                        {pdo.categories.map((category, index) => {
                            return (
                                <div className='ps-3'>
                                    < PdoIndicators key={index} category={category} pdoindex={pdoindex} catindex={index} />
                                </div>

                            )
                        })}

                    </div>
                )
            })}

        </div>
    )
}
