import { FaFilter } from "react-icons/fa";
import { StyledNav } from "./Styles/Nav.styles";
import Dropdown from 'react-bootstrap/Dropdown';

const Nav = () => {
    const username = JSON.parse(localStorage.getItem('admin'))
    // console.log(username)
    return (
        <>
            {/* <StyledNav> */}
            <div className="topnavNUC sticky-top shadow row justify-content-between  align-items-center p-4">
                <div className="logo col-6 d-flex align-items-center">
                    <img src="https://adminapi.blissful-rosalind.154-68-232-27.plesk.page/images/logo.png" alt="" />
                    <h3>SPESSE DASHBOARD</h3>
                </div>
                <div className="col-6">
                    <p className="userName fs-5 fw-bold">
                        {username.name}
                        <span className="loginAs"> you are login as (NUC Staff)</span></p>

                </div>
            </div>
            {/* </StyledNav> */}
        </>
    );
}

export default Nav;
