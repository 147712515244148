import React, { useEffect, useState } from "react";
import Nav from './Nav';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteError
} from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaPlus, FaHome, FaEye, FaTrash, FaEdit, FaComments, FaSearch, FaRecycle } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import axios from 'axios'



import AddCenter from './AddCenter'
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

const GetAllCenters = () => {
    const user_type = JSON.parse(localStorage.getItem('admin'))
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState('');
    const [recentProgrammeLunched, SetRecentProgrammeLunched] = useState(null)
    const [loader, setLoader] = useState(false)
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [type, setType] = useState("");
    const [zone, setZone] = useState("");
    const [zonefilter, setFilterZone] = useState("");

    const handleClose = () => setShow(false);

    useEffect(() => {
        let token = localStorage.getItem("token");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/allcenters`, requestOptions)
            .then(response => response.json())
            .then(result => SetRecentProgrammeLunched(result))
            .catch(error => console.log('error', error));
    }, []);

    const getName = (event) => {
        setName(event.target.value);
    }
    const getCode = (event) => {
        setCode(event.target.value);
    }
    const getNumber = (event) => {
        setPhone(event.target.value);
    }
    const getEmail = (event) => {
        setEmail(event.target.value);
    }
    const getaddress = (event) => {
        setAddress(event.target.value);
    }
    const getType = (event) => {
        setType(event.target.value);
    }

    const getZone = (event) => {
        setZone(event.target.value);
    }

    const getZoneF = (event) => {
        setFilterZone(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center_name", name);
        urlencoded.append("email", email);
        urlencoded.append("phone_number", phone);
        urlencoded.append("type", type);
        urlencoded.append("address", address);
        urlencoded.append("zone", zone);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/createcenter`, requestOptions)
        const data = await response.json()
        if (response.status == 200) {
            console.log(response)
            setLoader(false);
            Swal.fire(
                'Success!',
                'Center Added Successfully!',
                'success'
            )
            window.location.reload();
        }

    }
    function handleFilter(e) {
        const query = e.target.value;

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/searchcenters/${query}`, requestOptions)
            .then(response => response.json())
            .then(result => SetRecentProgrammeLunched(result))
            .catch(error => console.log('error', error));
    }
    const handleDelete = (index, e, id) => {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/deletecenters/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire(
                    'Success!',
                    'Center Deleted Successfully!',
                    'success'
                )
                window.location.reload()

            })
            .catch(error => console.log('error', error));
    }
    const handleReset = (index, e, id) => {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/resetpassword/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire(
                    'Success!',
                    'Password Reset Successfully!',
                    'success'
                )
                window.location.reload()

            })
            .catch(error => console.log('error', error));
    }


    return (
        <StyledPageTitle>
            <div className="card-header border-bottom m-5" id="row2-card-header">
                <div className="top-sales-header">
                    <h1 className="title">Manage Centers</h1>
                    {
                        user_type.type != 'View' &&
                        <div>

                            <button className="btn btn-success btnAdd" onClick={handleShow}>
                                <FaPlus />Add New Center
                            </button>
                        </div>
                    }


                    <div>
                        <Form.Group className="pull flex" controlId="formBasicPassword">

                            <input className="form-control" onChange={handleFilter} type="text" placeholder="--search for centers---" />

                            <a href="/centers" className="btn btn-success m-2 w-50" >
                                <FaSearch />View All Center
                            </a>
                        </Form.Group>


                    </div>
                </div>
            </div>
            <div className="table">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Phone No.</th>
                            <th>Zone</th>
                            <th>Date Added</th>

                            <th></th>
                            <th></th>
                            <th></th>

                        </tr>
                        {
                            Array.isArray(recentProgrammeLunched) && (
                                recentProgrammeLunched.map((row, key) => (
                                    <>

                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.uniq_id}</td>
                                            <td>{row.phone_number}</td>
                                            <td>{row.zone}</td>
                                            <td>{row.created_at}</td>

                                            <td>
                                                <Link
                                                    to={`/center/${row.uniq_id}`}
                                                    className="btn btn-sm btn-success m-2"
                                                    title="Edit Record"
                                                >
                                                    <FaEye /> VIEW
                                                </Link>
                                            </td>

                                            {
                                                user_type.type != 'View' &&
                                                <>
                                                    <td>
                                                        <Link
                                                            to={`/edit-center/${row.id}`}
                                                            className="btn btn-sm btn-info m-2"
                                                            title="Edit Record"
                                                        >
                                                            <FaEdit /> EDIT
                                                        </Link>
                                                    </td>
                                                    <td>

                                                        <button className="btn btn-sm btn-danger m-2" onClick={e => handleDelete(key, e, row.id)}>
                                                            <FaTrash />Delete
                                                        </button>

                                                    </td>
                                                    <td>

                                                        <button className="btn btn-sm btn-danger m-2" onClick={e => handleReset(key, e, row.id)}>
                                                            <FaRecycle />Reset Password
                                                        </button>

                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    </>
                                ))
                            )
                        }


                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>

            <>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Center</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            loader ? <CircularProgress /> : ""
                        }
                        <form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Center Name</Form.Label>
                                <Form.Control type="text"
                                    onChange={getName}
                                    value={name}
                                />
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Center Code</Form.Label>
                                <Form.Control type="text"
                                    onChange={getCode}
                                    value={code}
                                />
                            </Form.Group> */}
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number"
                                    onChange={getNumber}
                                    value={phone}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Center Email</Form.Label>
                                <Form.Control type="email"
                                    onChange={getEmail}
                                    value={email}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                    onChange={getaddress}
                                    value={address}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select"
                                    onChange={getType}
                                    value={type}>
                                    <option></option>
                                    <option>Coventional Universities</option>
                                    <option>Universty of Technology</option>
                                    <option>University of Agriculture</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Geopolitical Zones</Form.Label>
                                <Form.Control as="select"
                                    onChange={getZone}
                                    value={zone}>
                                    <option></option>
                                    <option>North Central (NC)</option>
                                    <option>North East (NE)</option>
                                    <option>North West (NW)</option>
                                    <option>South West (SW)</option>
                                    <option>South East (SE)</option>
                                    <option>South South(SS)</option>

                                </Form.Control>
                            </Form.Group>
                            <button type="submit" class="btn btn-success" >
                                Save Changes
                            </button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>

        </StyledPageTitle>
    );
}

export default GetAllCenters;


