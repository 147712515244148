import MainBody from "./Components/MainBody"
import SideNav from "./Components/SideNav"
import ErrorPage from "./error-page"
import Login from './Components/Login';
import OTP from './Components/Otp';
import ManageCenter from './Components/ManageCenters';
import ManageProgramme from './Components/ManageProgramme';
import ViewCenter from './Pages/ViewCenter';
import Course from './Pages/Course'
import ViewCenterStudents from './Pages/ViewCenterStudents'
import EditCenter from './Pages/EditCenter'
import Verification from './Pages/Verification'
import ManageUser from './Pages/ManageUser'
import EditUser from './Pages/EditUser'
import ViewPermission from './Pages/ViewPermission'
import Review from './Pages/Review'
import Messages from './Pages/Messages'
import IvaDashboard from './Pages/IvaDashboard';
import CourseView from './Pages/CourseView';
import IvaCenter from './Pages/IvaCenter';
import CourseViewAdmin from './Pages/CourseViewAdmin';
import CenterViewCourse from './Pages/CourseViewAdmin';
import Settings from './Pages/Settings';
import SettingsUser from './Pages/Settingsadduser';
import ManagePartners from './Components/ManagePartners';
import CenterViewFaculty from './Pages/CourseViewFaculty';
import CenterViewFacultyIVA from './Pages/IVACourseViewFaculty';
import Certificates from './Pages/ViewCertificates';
import EditPartner from './Pages/EditPartner';
import CenterPartners from './Pages/ViewCentersPartners';
import CenterIvaPartners from './Pages/ViewIvaPartners';
import Activity from './Pages/ActivityLog';
import CourseEval from './Pages/CourseEval';
import CourseEvalIva from './Pages/CourseEvalIva';
import PdoSummary from './pdo-reports/Pdo-Summary';
import PdoTypes from './pdo-reports/Pdo-Types';
import { createBrowserRouter } from "react-router-dom";
import ManageDashboards from "./WomenAffairs/ManageDashboards";
import WomenAffairsDash from "./WomenAffairs/WomenAffairsDashboard";

import ActualDates from "./pdo-reports/ActualDates/ActualDates";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/women_affairs",
        element: <WomenAffairsDash />,
    },
    {
        path: "/dashboard/environment",
        element: <WomenAffairsDash />,
    },
    {
        path: "/dashboard/bpp",
        element: <WomenAffairsDash />,
    },
    {
        path: "/dashboard",
        element: <MainBody />,
    },
    {
        path: "/manage-dashboards",
        element: <ManageDashboards />,
    },
    {
        path: "pdo-reports",
        element: <PdoSummary />,
    },
    {
        path: "/pdo-dates",
        element: <ActualDates />,
    },
    {
        path: "pdo-reports/:id",
        element: <PdoTypes />,
    },
    {
        path: "/OTP",
        element: <OTP />,
    },

    {
        path: "/centers",
        element: <ManageCenter />,
    },

    {
        path: "/view-students/:id",
        element: <ViewCenterStudents />,
    },
    {
        path: "/programmes",
        element: <ManageProgramme />,
    },
    {
        path: "/center/:id",
        element: <ViewCenter />
    },
    {
        path: "/center/certificates/:id",
        element: <Certificates />
    },
    {
        path: "/center/partners/:id",
        element: <CenterPartners />
    },
    {
        path: "/iva/partners/:id",
        element: <CenterIvaPartners />
    },
    {
        path: "/course/:id",
        element: <Course />
    },
    {
        path: "/edit-center/:id",
        element: <EditCenter />
    },
    {
        path: "/edit-partner/:id",
        element: <EditPartner />
    },
    {
        path: "/verification",
        element: <Verification />
    },
    {
        path: "/users",
        element: <ManageUser />
    },
    {
        path: "/edit-user/:id",
        element: <EditUser />
    },
    {
        path: "/view-permision/:id",
        element: <ViewPermission />
    },
    {
        path: "/logout",
        element: <Login />
    },
    {
        path: "/review/:id",
        element: <Review />
    },
    {
        path: "/messages",
        element: <Messages />
    },
    {
        path: "/dashboardIva",
        element: <IvaDashboard />,
    },
    {
        path: "/courseview/:id/:center_id",
        element: <CourseView />,
    },
    {
        path: "/centersview/:id",
        element: <IvaCenter />,
    },

    {
        path: "/coursesview/:id/:center_id",
        element: <CenterViewCourse />,
    },
    {
        path: "/course-evaluation/:id/:center_id",
        element: <CourseEval />,
    },
    {
        path: "/course-evaluation-iva/:id/:center_id",
        element: <CourseEvalIva />,
    },
    {
        path: "/facultyview/:id/:center_id",
        element: <CenterViewFaculty />,
    },
    {
        path: "/facultyviewiva/:id/:center_id",
        element: <CenterViewFacultyIVA />,
    },
    {
        path: "/settings",
        element: <Settings />
    },
    {
        path: "/activitylogs",
        element: <Activity />
    },
    {
        path: "/partners",
        element: <ManagePartners />
    },
    {
        path: "/settings/adduser",
        element: <SettingsUser />
    },
    {
        path: "/women-affairs",
        element: <Login />
    },
    {
        path: "/bpp",
        element: <Login />
    },
    {
        path: "/environment",
        element: <Login />
    },
    {
        path: "/iva",
        element: <Login />
    }
]);