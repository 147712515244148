import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { useRouteError, useParams, Link } from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaUsers, FaPlus, FaHome, FaSignOutAlt, FaCog, FaFilePrescription, FaComments, FaShoppingBasket, FaAddressBook } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { StyledCards } from './Styles/cards';
import axios from 'axios'

import { StyledPoints } from "./Styles/Points.styles";
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";

import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";



import AddCenter from './AddCenter'
import IvaCenter from "../Pages/IvaCenter";
import CentersInfo from "./CentersInfo";
import CourseViewAdmin from "./CourseViewInfo";
import TotalCards from "./totalCards";

const Trainees = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [centerName, setCenterName] = useState("");
    const handleClose = () => setShow(false);
    const { id, center_id } = useParams();

    const [courses, setCourses] = useState("");



    return (
        <>
            {/* <StyledCards> */}
            <TotalCards id={center_id} />
            {/* </StyledCards> */}
            <div className="mt-4">
                <CourseViewAdmin />
                {/* <TraineesInfo /> */}

            </div>
        </>

    );
}

export default Trainees;


