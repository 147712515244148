import React, { useEffect, useState } from "react";
import axios from 'axios'
import Nav from './../Components/Nav'
import PageTitle from './../Components/PageTitle'
import { StyledMainBody } from './../Components/Styles/MainBody.styles';
import SideNav from './../Components/SideNav';

import Students from './../Components/Students';

const ManageCenter = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");

    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }

    return (
        <>
            <div className="row ">
                <div className="col-1">
                    <SideNav />
                </div>
                <div className="col-11">
                    <Nav />
                    <Students />

                </div>
            </div>
            {/* <SideNav />

            <StyledMainBody>

            </StyledMainBody> */}
        </>

    );
}

export default ManageCenter;
