import styled from "styled-components";

export const StyledEmail = styled.div`
    max-height: 80px;
    margin-top:10px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Space+Grotesk:wght@300;400&display=swap');
    
    * {
        font-family: 'Space Grotesk', sans-serif;
    }
    .loginp {
        fony-size: 25px;
        font-weight: 600;
       
    }
    input {
        width: 300px;
        min-height: 40px;
        border-radius: 1px;
        border: 1px solid #0A770C;
        padding-left: 30px;
    }
`