
import React, { useEffect, useState } from 'react'

export default function JustSchemas({ schemaTitle, schema, tracks, pdoRes, centers }) {

    const [trackitem, setTrackItem] = useState(tracks)


    return (
        <>
            <tr className="">

                <td className='fs-4 bg-success text-white'>
                    {schemaTitle}
                </td>
                <td>

                </td>
                <td >

                </td>
                {Array.isArray(centers) && centers.map(

                    (center, index) => {
                        return (
                            <td key="" className='p-2 mb-2'>
                                <p key="" className='p-2 text-center fs-6 fw-bold mb-2'>
                                    0
                                </p>
                            </td>
                        )
                    }
                )}





            </tr>








        </>
    )
}
