import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { Navigate, useNavigate, useParams, Link } from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaUsers, FaPlus, FaHome, FaSignOutAlt, FaCog, FaFilePrescription, FaComments, FaShoppingBasket } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { StyledCards } from './Styles/cards';
import axios from 'axios'

import { StyledPoints } from "./Styles/Points.styles";
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";

import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";

import { CircleIndicator } from 'react-indicators';

import AddCenter from './AddCenter'

const EditPartnerP = () => {
    var CircleIndicator = require('react-indicators').CircleIndicator;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [website, setWebsite] = useState("");
    const [image, setImage] = useState(null);

    // const [process, setProcess] = useState("");


    const handleClose = () => setShow(false);
    const { id } = useParams();



    const navigate = useNavigate();
    //get the last lunch programme course by the center

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = `${process.env.REACT_APP_BASE_URL}/api/singlepartners/${id}`;

            try {
                const { data: response } = await axios.get(url);

                console.log(response);
                console.log(response.name);
                setName(response.name);
                setWebsite(response.web_url)
                setImage(response.img_url)



            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', image);
        formData.append('website', website);
        formData.append('name', name);
        formData.append('id', id);



        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/editpartner`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log(response.data);
            Swal.fire({
                title: 'Partner Updated Succesfully',
                icon: 'success',
                confirmButtonText: 'close'

            })
            window.location.href = '/partners';

        } catch (error) {
            console.error(error);
        }


    }


    return (
        <>
            <StyledPointsTiles>
                <div className="top-sales-header">

                </div>
            </StyledPointsTiles >


            <div className='card'>

                <div className="formgroup">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3" controlId="asicEmail">
                            <label>Partner Name</label>
                            <input type="text" className="form-control"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>

                        <div className="mb-3" controlId="asicEmail">
                            <label>Website</label>
                            <input type="text" className="form-control"
                                onChange={(e) => setWebsite(e.target.value)}
                                value={website}
                            />
                        </div>
                        <div className="mb-3">
                            <input disabled type="file" placeholder={image} accept="image/*" onChange={(e) => setImage(e.target.files[0])} className="form-control" />
                        </div>

                        <button type="submit" class="btn btn-success" >
                            Save Changes
                        </button>
                    </form>
                </div>
            </div>



        </>

    );
}

export default EditPartnerP;


