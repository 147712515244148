import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { Navigate, useNavigate, useParams, Link } from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaPlus, FaHome, FaEye, FaTrash, FaEdit, FaComments, FaShoppingBasket } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { StyledCards } from './Styles/cards';
import axios from 'axios'

import { StyledPoints } from "./Styles/Points.styles";
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";

import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";

import { CircleIndicator } from 'react-indicators';

import userPermission from './userPermission'

const Permission = () => {
    var CircleIndicator = require('react-indicators').CircleIndicator;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);



    const [process, setProcess] = useState("");
    const [getUserMenu, setGetUserMenu] = useState(null);

    const handleClose = () => setShow(false);
    const { id } = useParams();



    const navigate = useNavigate();
    //get the last lunch programme course by the center

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = "https://stockmgt.gapaautoparts.com/api/";

            try {
                const { data: response } = await axios.get(url + `GetMenu/${id}`);

                setGetUserMenu(response.GetMenu);
                console.log(response.GetMenu);



            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);



    const handleDelete = (index, e, id) => {
        setGetUserMenu(getUserMenu.filter((v, i) => i !== index));
        try {

            // set configurations
            const configuration = {
                method: "post",
                url: `https://stockmgt.gapaautoparts.com/api/DeleteUser/${id}`,

            };



            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'User Deleted Successfully!',
                            'success'
                        )



                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })


        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }



    return (
        <>
            <StyledPointsTiles>
                <div className="top-sales-header">

                </div>
            </StyledPointsTiles >
            <StyledCards>

                <div className='EditCard'>

                    <div className="formgroup">


                        <div className="error"> {process}</div>

                        <h1 className="EditTitle">Manage User Permission</h1>

                        <div className="mb-2 " >
                            <b>Click on Add or Remove Button to Manage User's Permission</b>
                            <table className="customTable">
                                <tr>
                                    <th>SN</th>
                                    <th>MENU</th>
                                    <th>ACTION</th>
                                </tr>

                                {
                                    getUserMenu?.length > 0 && (
                                        getUserMenu.map((row, key) => (
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>{row.Menu}</td>
                                                <td>
                                                    <button className="btn btn-success m-2" >
                                                        Add
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                }

                            </table>

                            <Link to="/users" class="btn btn-success mt-5" >
                                Back
                            </Link>
                        </div>
                    </div>
                </div>
            </StyledCards>


        </>

    );
}

export default Permission;


