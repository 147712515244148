import React, { useState } from 'react';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { router } from './router';



function App() {

  return (
    <div className="Container">


      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </div>
  );
}

export default App;
