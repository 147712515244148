import React, { useState } from 'react'
import Nav from '../Components/Nav';
import SideNav from '../Components/SideNav';
import Summary_Pdo from './summary_Pdo';

export default function PdoSummary() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");

    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }

    return (
        <>

            <div className="row ">
                <div className="col-1">
                    <SideNav />
                </div>
                <div className="col-11">
                    <Nav />

                    <Summary_Pdo />

                </div>
            </div>

        </>

    );
}
