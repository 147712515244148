import styled from "styled-components";

export const StyledRememberMe = styled.div`
    max-height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Space+Grotesk:wght@300;400&display=swap');
    input {
    }
    button {
        font-family: 'Space Grotesk', sans-serif;
        background-color: #0A770C;
        color: #fff;
        font-size: 17px;
        font-weight: 200;
        border: 1px solid #A6CE34;
        border-radius: 0px;
        padding: 13px 45px;
        margin-left: auto;
        cursor: pointer;
    }
    h4 {
        font-family: 'Space Grotesk', sans-serif;
        font-size: 16px;
        font-weight: 200;
        opacity: 0.7;
        margin-left: 10px; 
    }
`