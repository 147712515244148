import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { useRouteError, useParams, Link } from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaUsers, FaPlus, FaHome, FaSignOutAlt, FaCog, FaFilePrescription, FaComments, FaShoppingBasket, FaAddressBook } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { StyledCards } from './Styles/cards';
import axios from 'axios'

import { StyledPoints } from "./Styles/Points.styles";
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";

import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";

import AddCenter from './AddCenter'
import IvaCenter from "../Pages/IvaCenter";
import CentersInfo from "./CentersInfo";
import CourseViewAdmin from "./CourseViewInfo";
import TotalCards from "./totalCards";
import { CSVLink } from "react-csv";
import { Eject } from "@mui/icons-material";

const EvalProper = () => {
    const { id, center_id } = useParams();
    const [show, setShow] = useState(false);
    const [searchResult, setSearchResult] = useState('');
    const [columns, setColumns] = useState([
        {
            name: "Did you complete the course you enrolled for? ",
            value: false,
            api: 'completed_course',
        },
        {
            name: "Did course content meet your expectations? ",
            value: false,
            api: 'expectations',

        },
        {
            name: "Was the mode of delivery convenient for learning? ",
            value: false,
            api: 'mode_of_delivery_convenient',

        },
        {
            name: "Was the duration of the course reasonable? ",
            value: false,
            api: 'duration_reasonable',

        },
        {
            name: "Is the course content relevant for your work/Career development? ",
            value: false,
            api: 'course_relevant',

        },
        {
            name: "If “No”, what aspect do you think would be useful for your work/career but were not covered? ",
            value: false,
            api: 'where_would_it_be_relevant',

        },
        {
            name: "Are you willing to apply the new skills you have learnt from this course going forward? ",
            value: false,
            api: 'skills_applicable',

        },
        {
            name: "Was the mode of delivery used appropriate for the course you enrolled? ",
            value: false,
            api: 'delivery_appropiate',

        },
        {
            name: "If “No”, what mode of delivery would have been most appropriate? ",
            value: false,
            api: 'which_delivery_appropiate',

        },
        // {
        //     name: "Were the online courses you enrolled for self-paced? ",
        //     value: false,
        //     api: 'completed_course',

        // },
        {
            name: "How would you rate the mode of delivery for the  course you enrolled",
            value: false,
            api: 'ratings_mode_of_delivery',

        },
        {
            name: "How would you rate the quality course content? ",
            value: false,
            api: 'ratings_course_quality',

        },
        {
            name: "How would you rate the duration of the course? ",
            value: false,
            api: 'ratings_course_duration',

        },
        // {
        //     name: "How would you rate the delivery of face-to-face courses ",
        //     value: false,
        //     api: 'completed_course',

        // },
        {
            name: "Overall, how would you rate your satisfaction of the PES track course you have enrolled for? ",
            value: false,
            api: 'overall_satisfaction',

        },
        {
            name: "Did you face any form of harassment or discrimination by faculties since you enrolled for the program? ",
            value: false,
            api: 'harrasment',

        },
        {
            name: "What kind for harassment did you face? ",
            value: false,
            api: 'harrasment_kind',

        },
        {
            name: "Did you report such incident to appropriate authorities? ",
            value: false,
            api: 'report_harrasment',

        },
        {
            name: "If “Yes”, was the issue resolved? ",
            value: false,
            api: 'harrasment_resolved',

        },
        {
            name: "What will you suggest to be done differently to improve grievance redress mechanism in the centers?",
            value: false,
            api: 'harrasment_suggestion',

        },

    ])
    const [rand, setRand] = useState('');
    const [indices, setIndices] = useState([]);
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/detailedRatings/${id}`, requestOptions)
            .then(response => response.json())
            .then(result =>
                setSearchResult(result),
            )
            .catch(error => console.log('error', error));
    }, [])

    useEffect(() => {
        let indexlocal = localStorage.getItem('columnitems')
        if (indexlocal != null) {
            setIndices(JSON.parse(indexlocal))
        }
    }, [rand])

    function selectedIndices(index) {
        let indexArr = indices;

        function addItemIfNotExist(index) {
            const itemExists = indexArr.some((prod) => prod === index);
            if (itemExists) {
                let removed = indexArr.filter((filtered) => { return filtered != index })
                let value = JSON.stringify(removed)
                localStorage.setItem('columnitems', value)
                setRand(Math.random())
            } else {
                indexArr.push(index);
                let value = JSON.stringify(indexArr)
                localStorage.setItem('columnitems', value)
                setRand(Math.random())
            }
        }
        addItemIfNotExist(index);
        // indexArr.push(index);
        // setIndices(indexArr);
        // console.log(indices);
    }
    return (
        <>
            {/* <StyledCards> */}

            <TotalCards id={center_id} />
            {/* </StyledCards> */}
            <div className="mt-4">
                {/* {columns?.completed_course} */}

                <div className="mb-3">
                    <div className="d-flex justify-content-between px-3" onClick={() => {
                        setShow(!show)
                    }}>
                        <button className="btn btn-success btn-sm">Filter Table</button>
                        {Array.isArray(searchResult) && <CSVLink data={searchResult}>
                            <button className='btn btn-success'><Eject /> Export Data</button></CSVLink>}


                    </div>

                    <div className={show ? "bg-white shadow p-3 position-absolute" : 'd-none'}>

                        <div className="mb-3 d-flex justify-content-between">
                            <button className="btn btn-sm btn-danger" onClick={() => {
                                localStorage.removeItem("columnitems")
                                window.location.reload()
                            }}>Reset</button>
                            <div onClick={() => {
                                setShow(!show)
                            }}>
                                <button className="btn btn-success btn-sm">Close</button>
                            </div>
                        </div>
                        {Array.isArray(columns) && columns.map((item, index) => {
                            return (
                                <div key={index}>
                                    <input onClick={() => {
                                        selectedIndices(index)
                                    }} type="checkbox" checked={indices.includes(index) ? true : false} className="me-2" />
                                    <label>{item.name}</label>
                                </div>

                            )
                        })}
                    </div>

                </div>

                <div className='text-dark table-responsive overflow-scroll'>
                    <table className='table table-sm table-hover'>
                        <thead className='fw-bold'>
                            <td className='fw-bold'>S/N</td>
                            <td className='fw-bold'>Email Address</td>
                            <td className='fw-bold'>Gender</td>
                            {Array.isArray(indices) && indices.map((item, index) => {
                                return (
                                    <td key={index} className='fw-bold'>{columns[item].name}</td>
                                )
                            })}

                        </thead>
                        <tbody>
                            {Array.isArray(searchResult) && searchResult.map((Result, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {searchResult.indexOf(Result) + 1}
                                        </td>
                                        <td>
                                            {Result.email_address}
                                        </td>
                                        <td>
                                            {Result.gender}
                                        </td>
                                        {Array.isArray(indices) && indices.map((item, index) => {
                                            return (
                                                <td key={index} className='fw-bold'>{Result[`${columns[item].api}`]}</td>
                                            )
                                        })}

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </>

    );
}

export default EvalProper;


