import styled from "styled-components";

export const StyledPoints = styled.div`
    padding: 10px 20px 80px 20px;
    margin: 10px 0;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    h1 {
        font-weight: 500;
        font-size: 23px;
        line-height: 3;
    }
    p {
        font-size: 18px;
        opacity: 0.9;
    }
    label{
        opacity: 0.6;
    }
    input{
        border: none;
        background: transparent;
        opacity: 1.5;
        margin-left: 8px;
        outline: none;
        font-size: 12px;
        width: 120px;
    }
`

