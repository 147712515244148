import styled from "styled-components";

export const StyledCards = styled.div`
    padding: auto;
    box-sizing: border-box;
    border-radius: 10px;
    margin: auto 20px;
    margin-top:8rem;

    h1 {
        font-weight: 500;
        font-size: 15px;
        line-height: 1;
    }

    p {
        font-size: 25px;
       
    }

    .cards{
        width:100%;
        height:120px;
        display:flex;
    }
    .card{
        width:17rem;
        height:120px;
        display:flex;
        background-color:green;
        padding:5px;
        margin-left:10px;
        box-shadow: 0px 1px 4px green;
        border-radius: 5px;
        color:#fff;
    }

    .title{
        height: 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #fff;
        margin-top:10px;
    }

    .figure{
        position: absolute;
        height: 17px;
        padding-top: 15px;
        padding-left: 80px;
        margin-top: 15%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 16px;
        color: #fff;

    }


    
`