import { Eject } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv';
import { Link, useParams } from 'react-router-dom';
import DLiSumReports from '../Components/DLiSumReports';
import SideNavIva from '../Components/IvaDashboards/Navigations/sideNav';


import TopNav from '../Components/IvaDashboards/Navigations/topNav'
import TopPills from '../Components/IvaDashboards/Navigations/topPills';
import IvaTotalCards from '../Components/IvatotalCards';
import TotalCards from '../Components/totalCards';

function IvaCenter() {
    const { id } = useParams();
    const [notify, setNotify] = useState(' ');
    const [tracki, Settraki] = useState('1');
    const [searchinfo, setSearchInfo] = useState({
        tracks: '',
        schema: '',
    })
    const [searchResult, setSearchResult] = useState('');
    const [tracks, setTracks] = useState('');
    const [schema, setSchema] = useState('');
    const [courses, setCourses] = useState('');
    useEffect(() => {
        const fetchData = () => {
            const allSchematicAreas = `${process.env.REACT_APP_BASE_URL}/api/schematic_areas`;
            const allTracks = `${process.env.REACT_APP_BASE_URL}/api/tracks`;
            // const allCourses = `${process.env.REACT_APP_BASE_URL}/api/courses`;
            const getallSchema = axios.get(allSchematicAreas);
            const getallTracks = axios.get(allTracks);
            // const getallCourse = axios.get(allCourses);
            axios.all([getallSchema, getallTracks]).then(
                axios.spread((...allData) => {
                    const allScema = allData[0].data;
                    const alltracks = allData[1].data;
                    // const allcourses = allData[2].data;
                    setTracks(alltracks)
                    setSchema(allScema)
                    // setCourses(allcourses)

                })
            )
        }
        fetchData()
    }, [])
    function Query(e) {
        const schema = e.target.value
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("schema_id", schema);
        urlencoded.append("track_id", tracki);
        urlencoded.append("center", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getallinschema`, requestOptions)
            .then(response => response.json())
            .then(result => setSearchResult(result))
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", id);
        urlencoded.append("track", tracki);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getalllaunched`, requestOptions)
            .then(response => response.json())
            .then(result => setSearchResult(result))
            .catch(error => console.log('error', error));
    }, [tracki]);
    function all() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getalllaunched`, requestOptions)
            .then(response => response.json())
            .then(result => setSearchResult(result))
            .catch(error => console.log('error', error));
    }
    return (
        <>
            <div className='mainbody'>
                <div className='row justify-content-center'>
                    <div className='col-1 navbarIVa text-center py-5 shadow '>
                        <SideNavIva />
                    </div>
                    <div className='col-11'>
                        <div className='d-flex navheader mb-5 justify-content-between align-items-center p-3 shadow'>
                            <TopNav />
                        </div>
                        <div className='container'>


                            <div className='row g-3 text-light justify-content-between mb-3'>
                                <a onClick={() => { Settraki('1') }} className={tracki == '1' ? 'selectedtracks col-1  d-flex justify-content-center ' : ' tracks col-1  d-flex justify-content-center '}>
                                    <p className='fw-bold'> Track A</p>
                                </a>

                                <a onClick={() => { Settraki('2') }} className={tracki == '2' ? 'selectedtracks col-1  d-flex justify-content-center ' : ' tracks col-1  d-flex justify-content-center '}>
                                    <p className='fw-bold'> Track B</p>

                                </a>
                                <a onClick={() => { Settraki('3') }} className={tracki == '3' ? 'selectedtracks col-1  d-flex justify-content-center ' : ' tracks col-1  d-flex justify-content-center '}>
                                    <p className='fw-bold'> Track C</p>

                                </a>
                                <a onClick={() => { Settraki('4') }} c className={tracki == '4' ? 'selectedtracks col-1  d-flex justify-content-center ' : ' tracks col-1  d-flex justify-content-center '}>
                                    <p className='fw-bold'> Track D</p>

                                </a>
                                <a onClick={() => { Settraki('5') }} className={tracki == '5' ? 'selectedtracks col-1  d-flex justify-content-center ' : ' tracks col-1  d-flex justify-content-center '}>
                                    <p className='fw-bold'> Track E</p>

                                </a>
                            </div>
                            <div className='py-2'>

                                <IvaTotalCards id={id} />
                            </div>

                            <DLiSumReports tracki={tracki} id={id} />

                            <div className='row mb-3'>
                                <div className=''>
                                    <div>
                                        <label>Select Schematic Area</label>
                                        <div className='input-group'>
                                            <select className='form-control' onChange={Query}>
                                                <option value="none">--select schematic areas ---</option>
                                                {Array.isArray(schema) && schema.map(schema => {
                                                    return (
                                                        <option value={schema.id} key={schema.id}>{schema.title}</option>

                                                    )
                                                })
                                                }
                                            </select>
                                            <button onClick={all} className='btn-primary btn'>All</button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='card p-4'>
                                <div className='text-end'>
                                    {Array.isArray(searchResult) && <CSVLink data={searchResult}>
                                        <button className='btn btn-success'><Eject /> Export Data</button></CSVLink>}

                                </div>
                                <div className='text-dark overflow-scroll'>
                                    <table className='table table-sm table-hover'>
                                        <thead>
                                            <th>Course No</th>
                                            <th>Certificate Title</th>
                                            <th>Course Title</th>
                                            <th>Date Announced </th>
                                            <th>Offerings Per year</th>
                                            <th>actions</th>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(searchResult) && searchResult.map(Result => {
                                                return (
                                                    <tr key={Result.id}>
                                                        <td>
                                                            {searchResult.indexOf(Result) + 1}
                                                        </td>
                                                        <td>
                                                            {
                                                                Result.cert_title == null ? 'Short Course' : Result.cert_title
                                                            }
                                                        </td>
                                                        <td>
                                                            {Result.course_title}
                                                        </td>

                                                        <td>
                                                            {Result.announcement_date}
                                                        </td>
                                                        <td>{Result.offerings}</td>
                                                        <td>
                                                            <div className='btn-group'>
                                                                <a href={'/courseview/' + Result.id + '/' + id} className='btn btn-primary btn-sm'>
                                                                    view trainees
                                                                </a>
                                                                <a href={'/facultyviewiva/' + Result.id + '/' + id} className='btn btn-danger btn-sm'>
                                                                    view faculties</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}




export default IvaCenter
