import React, { useEffect, useState } from "react";

import Nav from '../Components/Nav'

import SideNav from '../Components/SideNav';
import { useParams } from "react-router-dom";
import CentersCertificates from "../Components/CenterCertificates";
import AllPartnersCent from "../Components/AllPartnersCenters";
import TotalCards from "../Components/totalCards";

const CenterPartners = () => {
    const { id } = useParams();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])
    const [centerDet, setCenterDet] = useState('');
    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");
    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/centername/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setCenterDet(result.data))
            .catch(error => console.log('error', error));
    }, [])
    return (
        <>

            <div className="row ">
                <div className="col-1">
                    <SideNav />
                </div>
                <div className="col-11">

                    <Nav />
                    <div className='p-4 d-flex justify-content-between'>
                        <h4>Center Name:{centerDet.name}</h4>
                        <h4>Center Id:{centerDet.uniq_id}</h4>

                    </div>
                    <TotalCards id={id} />
                    <div className="mt-4">
                        <AllPartnersCent />

                    </div>

                </div>
            </div>
            {/* <SideNav />
            <StyledMainBody>
                <Nav />
                <Centers />
            </StyledMainBody> */}
        </>

    );
}

export default CenterPartners;
