import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams, Link } from "react-router-dom";
import axios from 'axios'
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";
import Swal from "sweetalert2";

const ExportCenters = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [center_name, setName] = useState("");
    const [code, setCode] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [type, setType] = useState("");
    const [zone, setZone] = useState("");
    const handleClose = () => setShow(false);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = `${process.env.REACT_APP_BASE_URL}/api/singlecenter/${id}`;

            try {
                const { data: response } = await axios.get(url);

                console.log(response.name);
                setName(response.name);
                setCode(response.uniq_id);
                setPhone(response.phone_number);
                setEmail(response.email);
                setAddress(response.address);
                setType(response.type);
                setZone(response.zone);



            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    const getName = (event) => {
        setName(event.target.value);
    }
    const getCode = (event) => {
        setCode(event.target.value);
    }
    const getNumber = (event) => {
        setPhone(event.target.value);
    }
    const getEmail = (event) => {
        setEmail(event.target.value);
    }
    const getaddress = (event) => {
        setAddress(event.target.value);
    }
    const getType = (event) => {
        setType(event.target.value);
    }
    const getZone = (event) => {
        setZone(event.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // setProcess('Saving Changes, Please wait...');
        try {

            // set configurations
            const configuration = {
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/editcenteradmin`,
                data: {
                    id: id,
                    center_name: center_name,
                    center_code: code,
                    phone_number: phone,
                    email: email,
                    address: address,
                    type: type,
                    zone: zone,
                },
            };
            axios(configuration)
                .then((result) => {
                    console.log(result);
                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'Center Edited Successfully!',
                            'success'
                        )
                        // setProcess("");
                        navigate("/centers");


                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })

                        // setProcess('');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })

                        // setProcess('');
                    }

                })
                .catch((error) => {
                    console.log(error);
                    // setProcess('');
                })


        } catch (error) {
            console.log(error);
            // setError(error);
        }


    }

    return (


        <>
            <StyledPointsTiles>
                <div className="top-sales-header">

                </div>
            </StyledPointsTiles>

            <div className='card'>

                <div className="formgroup">
                    <form onSubmit={handleSubmit}>
                        <h1 className="EditTitle">Edit Center</h1>
                        <div className="mb-2" >
                            <p>Please fill all the require fields</p>
                            <div className="label">Center Name</div>
                            <input type="text"
                                onChange={getName}
                                value={center_name}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-2" controlId="formBasicEmail">
                            <div className="label">Center Code</div>
                            <input type="text" disabled
                                onChange={getCode}
                                value={code}
                                className="form-control"
                            />
                        </div>


                        <div className="mb-2" controlId="formBasicEmail">
                            <div className="label">Phone Number</div>
                            <input type="number"
                                onChange={getNumber}
                                value={phone}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-2" controlId="formBasicEmail">
                            <div className="label">Email</div>
                            <input type="email"
                                onChange={getEmail}
                                value={email}
                                className="form-control"
                            />
                        </div>

                        <div className="mb-2" controlId="formBasicEmail">
                            <div className="label">Address</div>
                            <textarea
                                onChange={getaddress}
                                value={address}
                                className="form-control"
                            >

                            </textarea>
                        </div>

                        <div className="mb-2" controlId="formBasicEmail">
                            <div className="label">Type</div>
                            <select
                                onChange={getType}
                                value={type}
                                className="form-control">
                                <option></option>
                                <option>Coventional Universities</option>
                                <option>Universty of Technology</option>
                                <option>University of Agriculture</option>
                            </select>
                        </div>


                        <div className="mb-2" controlId="formBasicEmail">
                            <div className="label">Geopolitical Zones</div>
                            <select
                                onChange={getZone}
                                value={zone}
                                className="form-control">
                                <option></option>
                                <option>North Central (NC)</option>
                                <option>North East (NE)</option>
                                <option>North West (NW)</option>
                                <option>South West (SW)</option>
                                <option>South East (SE)</option>
                                <option>South (SS)</option>
                            </select>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button type="submit" class="btn btn-success" >
                                Edit Center
                            </button>
                            <Link to="/centers" class="btn btn-success" >
                                Close
                            </Link>
                        </div>
                    </form>
                </div>
            </div>

        </>

    );
}

export default ExportCenters;


