import React, { useEffect, useState } from "react";
import axios from 'axios'

import { StyledTop } from './Styles/Tops.styles';
//import Table from 'react-bootstrap/Table';
import RecentProgrammeLunched from './RecentProgrammeLunched'
const Tops = () => {

    const [data, setData] = useState([])

    //const [loading, setLoading] = useState([])

    useEffect(() => {
        // let token = localStorage.getItem("token");

        const fetchData = async () => {
            //setLoading(true);
            let url = `${process.env.REACT_APP_BASE_URL}/api/allpartners`
            // let url = "https://stockmgt.gapaautoparts.com/api/admin/getAllPartners";

            try {
                const { data: response } = await axios.get(url);
                setData(response.message);
                console.log(response)
            } catch (error) {
                console.error(error.message);
            }
            //setLoading(false);
        }

        fetchData();
    }, []);



    return (


        <StyledTop>
            <div className='tops'>

                <RecentProgrammeLunched />

                <div className='top'>
                    <div class="topTitle">Technical Partners</div>

                    {
                        data.length > 0 && (
                            data.map((row, key) => (
                                <>
                                    <div className='partner' key={key}>
                                        <a className="nav-link" href={row.web_url}>
                                            <div className='partnerImage'><img alt="logo" width="50px" src={`${process.env.REACT_APP_BASE_URL}/partners/${row.img_url}`} /></div>
                                        </a>

                                        <div className='partnerName'>{row.name}</div>
                                    </div>
                                    <hr />
                                </>
                            ))
                        )
                    }
                </div>
            </div>
        </StyledTop>


    );
}

export default Tops;
