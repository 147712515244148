import React, { useState, useEffect } from "react";
import { useRouteError } from "react-router-dom";

import { StyledLoginForm } from './Styles/StyledLoginForm.styles'
import { StyledEmail } from './Styles/StyledEmail.styles'
import { StyledForgotPassword } from './Styles/StyledForgotPassword'
import { StyledRememberMe } from './Styles/StyledRememberMe'

import axios from 'axios'

import Swal from "sweetalert2";


let path = window.location.pathname;
let loginurl = path.includes('women-affairs') ? "login_externals" : path.includes('bpp') ? "login_externals" : path.includes('environment') ? "login_externals" : path.includes('iva') ? "login_externals" : "loginadmin";
// console.log(loginurl);
const LoginForm = () => {
    const [isLoggedIn, setisLoggedIn] = useState(null);
    const [email, SetEmail] = useState('');
    const [password, SetPassword] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // set configurations
            const configuration = {
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/${loginurl}`,
                data: {
                    email,
                    password,
                },
            };
            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {
                        localStorage.setItem("email", email);
                        localStorage.setItem("token", result.data.bearer_token);
                        localStorage.setItem("admin", JSON.stringify(result.data.user));

                        Swal.fire(
                            'Success!',
                            'You are login Successfully!',
                            'success'
                        )
                        window.location.href = "/otp";

                    } else if (result.status == 201 || result.status == 202) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.status,
                        })
                        // console.log(result)

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })
        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }

    useEffect(() => {
        localStorage.clear();
    }, [])

    return (
        <StyledLoginForm>

            <form className='loginForm' onSubmit={handleSubmit}>

                <div className='LoginTitle'>Sign in</div>
                <p>
                    Electronic Platform for Performance Monitoring of Centres of Excellence Verification and
                    Reporting
                </p>

                <StyledEmail>
                    <label className='loginp'>E-mail</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder='agency@spesse.edu.ng'
                        value={email}
                        onChange={(e) => SetEmail(e.target.value)}
                    />
                </StyledEmail>

                <StyledEmail>

                    <label className='loginp'>Password</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder='******************'
                        value={password}
                        onChange={(e) => SetPassword(e.target.value)}
                    />
                </StyledEmail>

                <StyledForgotPassword>

                </StyledForgotPassword>

                <StyledRememberMe>
                    {/* <input type="checkbox" name="Remember Me" id="" /> <h4>Remember Me</h4> */}
                    <button type="submit">Log In</button>
                </StyledRememberMe  >

            </form>
        </StyledLoginForm>
    );
}

export default LoginForm;