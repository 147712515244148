import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Nav from '../../Components/Nav'
import SideNav from '../../Components/SideNav'

export default function ActualDates() {
    const [availableDates, setAvailableDates] = useState('')
    const [updated, setUpdated] = useState('')
    const [date, setDate] = useState({
        actual_date: '',
        end_date: '',
    })
    useEffect(() => {


        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/dates`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setAvailableDates(result);
            })
            .catch(error => console.log('error', error));

    }, [updated])

    async function saveActualDate(e) {
        e.preventDefault()

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("start_date", date.actual_date);
        urlencoded.append("end_date", date.end_date);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/add_date`, requestOptions)
        const data = await response.json()
        if (response.status == 200) {
            Swal.fire(
                'Success!',
                'Date Created Successfully!',
                'success'
            )
            setUpdated(Math.random())
            // window.location.reload();
        }
    }

    function makeActive(id) {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/make_active_date/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire(
                    'Success!',
                    'Date Made Active!',
                    'success',
                    1000,
                )
                setUpdated(Math.random())
            })
            .catch(error => console.log('error', error));

    }
    function deleteDate(id) {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/delete_date/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire(
                    'Success!',
                    'Date Deleted Successfully!',
                    'success',
                    1000,
                )
                setUpdated(Math.random())
            })
            .catch(error => console.log('error', error));

    }
    return (
        <div className="row ">
            <div className="col-1">
                <SideNav />
            </div>
            <div className="col-11">
                <Nav />
                <div className='row p-3 pt-5 justify-content-around '>


                    <div className='col-4'>

                        <div className='mb-4'>
                            <h3>Create Verification Date</h3>
                        </div>

                        <form className='' onSubmit={saveActualDate}>
                            <div className='mb-3'>
                                <label>Actual Date</label>
                                <input type="date" onChange={(e) => {
                                    setDate({
                                        ...date,
                                        actual_date: e.target.value,
                                    })
                                }} required className='form-control' />
                            </div>
                            <div className='mb-3'>
                                <label>End Date</label>
                                <input type="date" onChange={(e) => {
                                    setDate({
                                        ...date,
                                        end_date: e.target.value,
                                    })
                                }} required className='form-control' />
                            </div>

                            <button className='btn-success btn'>
                                Save
                            </button>
                        </form>
                    </div>

                    <div className='col-8 pe-3'>


                        <table className='table table-hover table-responsive'>
                            <thead className='fw-bold'>
                                <th>S/N</th>
                                <th>Actual Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                            </thead>

                            <tbody>
                                {
                                    Array.isArray(availableDates) && availableDates.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {index + 1}
                                                </td>

                                                <td>
                                                    {item.start_date}
                                                </td>
                                                <td>
                                                    {item.end_date}
                                                </td>


                                                <td>
                                                    <div className='btn-group'>
                                                        {item.status == 'active' ? <button className='btn btn-sm btn-success'>Active</button> :
                                                            <button className='btn btn-sm btn-warning' onClick={() => { makeActive(item.id) }}>Make Active</button>
                                                        }

                                                        <button className='btn btn-sm btn-primary'>Edit</button>
                                                        <button className='btn btn-sm btn-danger' onClick={() => { deleteDate(item.id) }}>Delete</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
