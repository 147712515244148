import React, { useEffect, useState } from "react";

import Nav from '../Components/Nav'

import SideNav from '../Components/SideNav';
import { useParams } from "react-router-dom";
import CentersCertificates from "../Components/CenterCertificates";
import AllPartnersCent from "../Components/AllPartnersCenters";
import TotalCards from "../Components/totalCards";
import SideNavIva from "../Components/IvaDashboards/Navigations/sideNav";
import TopNav from "../Components/IvaDashboards/Navigations/topNav";

const CenterIvaPartners = () => {
    const { id } = useParams();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])
    const [centerDet, setCenterDet] = useState('');
    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");
    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/centername/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setCenterDet(result.data))
            .catch(error => console.log('error', error));
    }, [])
    return (
        <>

            <div className='mainbody'>
                <div className='row justify-content-center'>
                    <div className='col-1 navbarIVa text-center py-5 shadow '>
                        <SideNavIva />
                    </div>
                    <div className='col-11'>
                        <div className='d-flex navheader mb-5 justify-content-between align-items-center p-3 shadow'>
                            <TopNav />
                        </div>
                        <div className='container'>


                            <AllPartnersCent />


                        </div>
                    </div>
                </div>
            </div>



        </>

    );
}

export default CenterIvaPartners;
