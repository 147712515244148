import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { useRouteError } from "react-router-dom";
import { StyledCards } from './Styles/Cards.syles';
import Table from 'react-bootstrap/Table';

import axios from 'axios'

import Swal from "sweetalert2";


const RecentProgrammeLunched = () => {
    const [loading, setLoading] = useState(true);
    const [recentProgrammeLunched, SetRecentProgrammeLunched] = useState(null)

    // console.log(recentProgrammeLunched);
    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);
            let url = `${process.env.REACT_APP_BASE_URL}/api/getrecentcourses`;
            // let url = "https://stockmgt.gapaautoparts.com/api/admin/getRecentProgrammeLunched";

            try {
                const { data: response } = await axios.get(url);
                SetRecentProgrammeLunched(response.courses);
                // console.log(response)


            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    function redirect(center_id) {
        window.location.assign(`/center/${center_id}`)
        // console.log(center_id)
    }
    return (
        <div className='top2'>
            <div className="card-header border-bottom" id="row2-card-header">
                <div className="top-sales-header">
                    <h2 className="card-title fw-semibold">Recent Launched Courses</h2>
                </div>
            </div>
            <div className="table">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Course Title</th>
                            <th>Course  Code</th>
                            <th>Track </th>
                            <th>Schematic Area </th>
                            <th>Date Launched</th>
                        </tr>
                        {
                            recentProgrammeLunched?.length > 0 && (
                                recentProgrammeLunched.map((row, key) => (
                                    <>
                                        <tr key={key} onClick={() => redirect(row.center_id)}>
                                            <td>{key + 1}</td>
                                            <td>{row.course_title}</td>
                                            <td>{row.course_code}</td>
                                            <td className="text-nowrap">{row.track_title}</td>
                                            <td>{row.schematic_title}</td>

                                            <td>{row.created_at}</td>
                                        </tr>

                                    </>
                                ))
                            )
                        }


                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RecentProgrammeLunched;


