import { CircularProgress } from "@mui/material";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function AllPartnersCent() {
    const [partners, setPartners] = useState([])
    const { id } = useParams()
    // const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    // const [update, setUpdate] = useState('');
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/centpartners/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setPartners(result.certs.reverse()))
            .catch(error => console.log('error', error));
    }, [])

    return (<>
        <div>
            <div className='d-flex align-items-center justify-content-between py-4'>
            </div>
            <div className="bg-info p-4 shadow rounded-0  table-responsive ">

                <div>
                    <h6 className="fw-bold">Total No of Partners: {partners.length}</h6>
                </div>
                <table className="tableData table table-striped table-sm table-hover  ">
                    <thead>
                        <tr>
                            <th>LOGO</th>
                            <th>NAME</th>
                            <th>WEBSITE</th>
                            <th>MOU</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(partners) &&
                            partners.map(partner => {
                                return (
                                    <tr className='align-items-center '>
                                        <td className="col-1">
                                            <a href={`${process.env.REACT_APP_BASE_URL}/centerpartners/logo/${partner.logo}`}>
                                                <img className="img-fluid" height={50} src={`${process.env.REACT_APP_BASE_URL}/centerpartners/logo/${partner.logo}`} alt="Logo" />
                                            </a></td>
                                        <td>{partner.name}</td>
                                        <td>{partner.web_url} </td>
                                        <td> <a href={`${process.env.REACT_APP_BASE_URL}/centerpartners/mou/${partner.mou}`}>{partner.mou}</a></td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
        </div >

    </>)
}

export default AllPartnersCent;