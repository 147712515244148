import React, { useEffect, useState } from "react";
import axios from 'axios'
import Nav from '../Components/Nav'
import PageTitle from '../Components/PageTitle'
import { StyledMainBody } from '../Components/Styles/MainBody.styles';
import SideNav from '../Components/SideNav';

import Centers from '../Components/Center';
import Trainees from "../Components/TraineesCenters";
import Facultiees from "../Components/FacultiesCenter";
import FacultyViewsIVA from "../Components/FacultyViewsIVA";
import SideNavIva from "../Components/IvaDashboards/Navigations/sideNav";
import TopNav from "../Components/IvaDashboards/Navigations/topNav";

const CenterViewFacultyIVA = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");

    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }

    return (
        <>

            <div className='mainbody'>
                <div className='row justify-content-center'>
                    <div className='col-1 navbarIVa text-center py-5 shadow '>
                        <SideNavIva />
                    </div>
                    <div className='col-11'>
                        <div className='d-flex navheader mb-5 justify-content-between align-items-center p-3 shadow'>
                            <TopNav />
                        </div>
                        <div className='container'>
                            <FacultyViewsIVA />
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default CenterViewFacultyIVA;
