import { Eject } from '@mui/icons-material';
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom'
import JustSchemas from './pdo-components/JustSchemas';
import SchematicRows from './pdo-components/schematicrows';
import PdoIndicators from './PdoIndicators'
import { pdoNames } from './pdonames'

export default function Pdo_proper() {
    const { id } = useParams();
    const [results, setResults] = useState('')
    const [centers, setCenters] = useState('')
    const [schema, setSchema] = useState('')
    const rowArray = ['Public',
        'Private',
        'Unemployed',
        'New Staff',
        'Existing Staff',
        'Male',
        'Female']
    const [track, setTracks] = useState('')
    const [selectedPdo, setSelectedPdo] = useState('')
    const [pdoRes, setPdoRes] = useState('')
    const [activeDate, setActiveDate] = useState('')
    useEffect(() => {
        function pdogetter() {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/api/specific_pdo/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {

                    setSelectedPdo(result.pdotitle)
                    if (result.pdoprop == undefined) {
                        setPdoRes('')
                    } else {
                        setPdoRes(result.pdoprop)
                        setActiveDate(result.active_date)

                    }
                })
                .catch(error => console.log('error', error));

        }
        pdogetter()

    }, []);

    //this gets all centers

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/allcenters`, requestOptions)
            .then(response => response.json())
            .then(result => setCenters(result))
            .catch(error => console.log('error', error));
    }, []);

    useEffect(() => {
        const fetchData = () => {
            const allSchematicAreas = `${process.env.REACT_APP_BASE_URL}/api/schematic_areas`;
            const allTracks = `${process.env.REACT_APP_BASE_URL}/api/tracks`;
            const getallSchema = axios.get(allSchematicAreas);
            const getallTracks = axios.get(allTracks);
            axios.all([getallSchema, getallTracks,]).then(
                axios.spread((...allData) => {
                    const allScema = allData[0].data;
                    const alltracks = allData[1].data;
                    setTracks(alltracks)
                    setSchema(allScema)
                })
            )
        }
        fetchData()
    }, [])
    return (
        <div className='p-3'>
            <div className='p-3 bg-success text-white'>
                <p>{selectedPdo != '' && selectedPdo.title}</p>

                {/* {Array.isArray(pdoRes) && <CSVLink data={pdoRes}>
                    <button className='btn btn-success'><Eject /> Export Data</button></CSVLink>} */}
            </div>

            <div className='p-3 w-100 table-responsive'>

                <table className='table table-responsive table-hover w-100'>
                    <thead className='fw-bold align-item-center justify-content-around'>
                        <th className='fw-bold'>
                            Disaggregations
                        </th>
                        <th className='fw-bold'>
                            Actual Date
                            (Current)
                            <p>{activeDate.start_date}</p>
                        </th>
                        <th className='fw-bold'>
                            End Date
                            <p>{activeDate.end_date}</p>
                        </th>
                        {Array.isArray(centers) && centers.map((center, index) => {
                            return (
                                <th className='fw-bold text-center' key={index}>
                                    {center.name}
                                    <p>({center.uniq_id})</p>
                                </th>
                            )
                        })}




                    </thead>
                    <tbody>
                        {
                            id == 6 ?
                                (Array.isArray(rowArray) && rowArray.map((item, index) => {
                                    return (
                                        <JustSchemas schemaTitle={item} schema={item} key={index} pdoRes={pdoRes} centers={centers} tracks={track} />

                                        // <SchematicRows  pdoRes={pdoRes} centers={centers} tracks={track} />
                                    )
                                }
                                ))
                                :
                                (Array.isArray(schema) && schema.map((item, index) => {
                                    return (
                                        <SchematicRows schemaTitle={item.title} schema={item} key={index} pdoRes={pdoRes} centers={centers} tracks={track} />
                                    )
                                }
                                ))
                        }

                    </tbody>
                </table>

            </div>

            {/* {pdoNames.map((pdo, index) => {
                return (
                    <div>
                        <h4 className='bg-success text-white p-3'>{pdo.title}</h4>
                        {pdo.categories.map((pdo, index) => {
                            return (
                                <div className='ps-3'>
                                    < PdoIndicators key={index} pdo={pdo} index={index} />
                                </div>

                            )
                        })}

                    </div>
                )
            })} */}

        </div>
    )
}
