import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { useRouteError, useParams, Link } from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaUsers, FaPlus, FaHome, FaSignOutAlt, FaCog, FaFilePrescription, FaComments, FaShoppingBasket } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { StyledCards } from './Styles/cards';
import axios from 'axios'

import { StyledPoints } from "./Styles/Points.styles";
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";

import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";



import AddCenter from './AddCenter'

const Students = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);

    const [getStudents, SetgetStudents] = useState("");
  
    const [centerName, setCenterName] = useState("");

    const handleClose = () => setShow(false);
    const { id } = useParams();

    const [courses, setCourses] = useState("");


    //get the last lunch programme course by the center

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = "https://stockmgt.gapaautoparts.com/api/admin/";

            try {
                const { data: response } = await axios.get(url + `GetCenterById/${id}`);
           

                console.log(response);
                setCenterName(response.result.center_name)
                SetgetStudents(response.students);

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);




   
    return (

        <>
         <StyledPointsTiles>
                <h1>{centerName} Students</h1>
            </StyledPointsTiles >
            <StyledCards>
            <table className="customTable">
                <thead>
                    <tr>
                        <th>SN</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Faculty</th>
                    </tr>
                </thead>

                <tbody>
                   
                </tbody>
            </table>
            </StyledCards>
            
        </>
    );
}

export default Students;
