import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { useRouteError } from "react-router-dom";


import axios from 'axios'

import Swal from "sweetalert2";
import Table from 'react-bootstrap/Table';

const Progress = (props) => {
    const [loading, setLoading] = useState(true);
    const [module, SetModule] = useState(null)

    const [prog, setProg] = useState('1%');

    return (

       <div className="progress">
            <div className="bar" 
            style={{
                width: '1%',
              }}
              >
                {props.center_id}

              </div>
       </div>

    );
}

export default Progress;

