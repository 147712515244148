import React, { useEffect, useState } from "react";
import Nav from './Nav';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteError
} from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaPlus, FaHome, FaEye, FaPenAlt, FaTrash, FaComments, FaShoppingBasket } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import axios from 'axios'

import Swal from "sweetalert2";

import AddCenter from './AddCenter'

const GetAllCenters = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [recentProgrammeLunched, SetRecentProgrammeLunched] = useState(null)

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [type, setType] = useState("");

    const handleClose = () => setShow(false);

    const handleDelete = (index,e, id) => {
        SetRecentProgrammeLunched(recentProgrammeLunched.filter((v, i) => i !== index));
        try {

            // set configurations
            const configuration = {
                method: "post",
                url: `https://stockmgt.gapaautoparts.com/api/center/DeleteProgramme/${id}`,
               
            };



            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'Programme Deleted Successfully!',
                            'success'
                        )

                      
                        
                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })


        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = "https://stockmgt.gapaautoparts.com/api/admin/getAllProgrammes";

            try {
                const { data: response } = await axios.get(url);
                SetRecentProgrammeLunched(response.result);
                console.log(response)


            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    const getName = (event) => {
        setName(event.target.value);
    }
    const getCode = (event) => {
        setCode(event.target.value);
    }

    const getType = (event) => {
        setType(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            // set configurations
            const configuration = {
                method: "post",
                url: "https://stockmgt.gapaautoparts.com/api/center/AddProgramme",
                data: {
                    title: name,
                    code: code,
                    type: type,
                },
            };



            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'Programme Added Successfully!',
                            'success'
                        )

                        window.location.reload();
                        
                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })


        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }

    return (
        <StyledPageTitle>
            <div className="card-header border-bottom m-5" id="row2-card-header">
                <div className="top-sales-header">
                    <h1 className="title">Manage Courses</h1>
                    <div>

                        <button className="btn btn-success btnAdd" onClick={handleShow}>
                            <FaPlus />Add New Course
                        </button>
                    </div>
                </div>
            </div>
            <div className="table">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Course Name</th>
                            <th>Course Code</th>
                            <th>Course Type</th>
                            <th>Date Added</th>
                            <th></th>

                        </tr>
                        {
                            recentProgrammeLunched?.length > 0 && (
                                recentProgrammeLunched.map((row, key) => (
                                    <>
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{row.title}</td>
                                            <td>{row.code}</td>
                                            <td>{row.type}</td>
                                            <td>{row.added_at}</td>

                                            <td>
                                            <Link
                                                    to={`/course/${row.id}`}
                                                    className="btn btn-success m-2"
                                                    title="Edit Record"
                                                >
                                                    <FaPenAlt />Edit
                                                </Link>

                                               
                                                <button className="btn btn-danger m-2" onClick={e => handleDelete(key,e,row.id)}>
                                                    <FaTrash />Delete
                                                </button>

                                            </td>
                                        </tr>
                                    </>
                                ))
                            )
                        }


                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>

            <>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Programme</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Programme Name</Form.Label>
                                <Form.Control type="text"
                                    onChange={getName}
                                    value={name}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Programme Code</Form.Label>
                                <Form.Control type="text"
                                    onChange={getCode}
                                    value={code} />
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select"
                                    onChange={getType}
                                    value={type}>
                                    <option></option>
                                    <option>Diploma Programme</option>
                                    <option>Degree Programme</option>
                                    <option>Certificate Programme</option>
                                </Form.Control>
                            </Form.Group>
                            <button type="submit" class="btn btn-success" >
                                Save Changes
                            </button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>

        </StyledPageTitle>
    );
}

export default GetAllCenters;


