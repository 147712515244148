import styled from "styled-components";

export const StyledFullCard = styled.div`
    padding: auto;
    box-sizing: border-box;
    border-radius: 10px;
    margin: auto 20px;
    
        width:94%;
        height:350px;
        display:flex;
        flex-direction: column;
        background-color:#fff;
        box-shadow: 0px 1px 4px #E5E9F2;
        border-radius: 5px;
        padding:5px;
        margin: 1.5rem;
        margin-top:10rem;

        .title{
            height: 24px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #31394D;
            margin:10px;
        }

        .table{
            padding:5px;

        }
    
        .m-5{
            margin-top:2rem;
        }
        
       
   
`