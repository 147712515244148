import styled from "styled-components";

export const StyledLoginForm = styled.div`
    box-sizing: border-box;
    display:flex;
    width:100%;
    height:80vh;

    display: flex;
    align-items: center;
    justify-content: center;
    
    .loginForm{
        width:80%;
    }

    .LoginTitle{
        height: 24px;
        left: 12.54%;
        right: 75.63%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #31394D;
    }
    p{
       margin-top:20px;
        width: 523px;
        height: 43px;
        left: 106px;
        top: 339px;
        font-family: 'Colfax';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
    }
`