import React, { useEffect, useState } from "react";
import axios from 'axios'
import SideNav from "../Components/SideNav";
import Nav from "../Components/Nav";
import SettingsIndexAdd from "../Components/Settings/SettingsUserAdd";



const SettingsUser = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");

    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }

    return (
        <>

            <div className="row ">
                <div className="col-1">
                    <SideNav />
                </div>
                <div className="col-11">
                    <Nav />
                    <SettingsIndexAdd />
                    {/* <Trainees /> */}

                </div>
            </div>
        </>

    );
}

export default SettingsUser;
