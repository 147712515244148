import { Eject } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { CSVDownload, CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import SideNavIva from '../Components/IvaDashboards/Navigations/sideNav';
import TopNav from '../Components/IvaDashboards/Navigations/topNav';
import TopPills from '../Components/IvaDashboards/Navigations/topPills';
import CourseFeedback from './coursefeedback';

function CourseViewAdmin() {
    const { id, center_id } = useParams();
    const [searchResult, setSearchResult] = useState('')
    const [session, setSession] = useState('')
    const [sessionsi, setSessionsi] = useState(' ')
    const [offering, setOfferings] = useState(' ')
    const [course, setCourseData] = useState('')

    console.log(searchResult);
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        if (sessionsi != ' ') {
            urlencoded.append("session_id", sessionsi);
        }
        if (offering != ' ') {
            urlencoded.append("offering_num", offering);
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getregstudentcourse`, requestOptions)
            .then(response => response.json())
            .then(result =>
                setSearchResult(result),
            )
            .catch(error => console.log('error', error));
    }, [sessionsi, offering])
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getallsession/${center_id}`, requestOptions)
            .then(response => response.json())
            .then(result =>
                setSession(result),
            )
            .catch(error => console.log('error', error));
    }, [])

    return (
        <>
            <div className='container'>
                {
                    Array.isArray(searchResult.data) ?
                        <div className='my-5'>
                            <div className='row'>

                                <div className='col-6'>

                                    <div className='mb-3'>
                                        <span className='fw-bold' > Track:</span>  {searchResult.course.track_title}
                                    </div>
                                    <div className='mb-3'>
                                        <span className='fw-bold' > Schematic Area: </span>   {searchResult.course.schematic_title}
                                    </div>
                                    <div className='mb-3'>
                                        <span className='fw-bold' > Course Name: </span>   {searchResult.course.course_title}
                                    </div>
                                    <div className='mb-3'>
                                        <span className='fw-bold' >  Mode of Delivery:</span> {searchResult.course.mode_of_delivery}
                                    </div>
                                    <div className='mb-3'>
                                        <span className='fw-bold' > Course Start date:</span>  {searchResult.course.start_date}
                                    </div>
                                    <div className='mb-3'>
                                        <span className='fw-bold' > Course End date:</span>  {searchResult.course.end_date}
                                    </div>



                                    <div className='mt-5 '>
                                        <a href={`/course-evaluation/${id}/${center_id}`} className='btn btn-success'>View Detailed Evaluation for Course</a>

                                    </div>
                                </div>


                                <div className='col-6'>

                                    <CourseFeedback />
                                </div>


                            </div>

                            <hr />
                            <div>
                                <div className='mb-3'>
                                    {searchResult.course.track_title == 'Track A' &&
                                        <select className='form-control col-6 ' onChange={(e) => { setOfferings(e.target.value) }}>
                                            <option value="1">--select the offering--</option>
                                            <option value="1">1st Offering</option>
                                            <option value="2">2nd Offering</option>
                                            <option value="3">3rd Offering</option>
                                            <option value="4">4th Offering</option>
                                            <option value="5">5th Offering</option>
                                            <option value="6">6th Offering</option>
                                            <option value="7">7th Offering</option>
                                            <option value="8">8th Offering</option>
                                            <option value="9">9th Offering</option>
                                            <option value="10">10th Offering</option>
                                        </select>
                                    }
                                </div>
                                <div className='mb-3'>
                                    {searchResult.course.track_title == 'Track E' &&
                                        <select className='form-control col-6 ' onChange={(e) => { setSessionsi(e.target.value) }}>
                                            <option value="" key="">---select session---</option>
                                            {
                                                Array.isArray(session) && session.map(data => {
                                                    return (
                                                        <option value={data.id} key={data.id}>{data.title}//{data.status}</option>
                                                    )
                                                })
                                            }
                                        </select>

                                    }
                                </div>

                            </div>
                        </div> : ''
                }


                <div className='card p-4'>
                    <div className='text-end mb-3'>
                        {Array.isArray(searchResult.data) && <CSVLink data={searchResult.data}>
                            <button className='btn btn-success'><Eject /> Export Data</button></CSVLink>}

                    </div>
                    <div className='text-dark table-responsive'>
                        <table className='table table-sm table-hover'>
                            <thead className='fw-bold'>
                                <td className='fw-bold'>Trainee No </td>
                                <td className='fw-bold'>Trainee Name</td>
                                <td className='fw-bold'>Phone Number</td>
                                <td className='fw-bold'>Email</td>

                                <td className='fw-bold'>Gender </td>
                                <td className='fw-bold' >Age</td>
                                <td className='fw-bold'>Nationality</td>
                                <td className='fw-bold'>Highest Qualification</td>
                                <td className='fw-bold'>Employement</td>
                            </thead>
                            <tbody>
                                {Array.isArray(searchResult.data) && (searchResult.data.length == 0 ? <tr>
                                    <td colSpan={11} className="text-center p-4 fs-6">No data to Show</td>
                                </tr> : searchResult.data.map(Result => {
                                    return (
                                        <tr key={searchResult.data.indexOf(Result)}>
                                            <td>
                                                {searchResult.data.indexOf(Result) + 1}
                                            </td>
                                            <td>
                                                {Result.first_name + ' ' + Result.last_name}
                                            </td>
                                            <td>{Result.phone_number}</td>
                                            <td>
                                                {Result.email_address}
                                            </td>
                                            <td>
                                                {Result.gender}
                                            </td>
                                            <td>{Result.age}</td>
                                            <td>{Result.nationality}</td>
                                            <td>{Result.highest_qual}</td>
                                            <td>{Result.employment_stat}</td>
                                            <td>
                                                {Result.completed_status != 'completed' ? <button className='btn btn-sm btn-danger'>Not Completed</button> : <button className='btn btn-sm btn-success'>Completed</button>}
                                            </td>
                                            <td>
                                                {Result.ratings != null ? <button className='btn btn-sm btn-success'>Rated</button> : <button className='btn btn-sm btn-danger'>Not Rated</button>}
                                            </td>
                                        </tr>
                                    )
                                }))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CourseViewAdmin
