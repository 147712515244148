import { ArrowDownward, ArrowDropDown, ArrowRight, Close, SubscriptionsOutlined } from '@mui/icons-material';
import React, { useState } from 'react'

export default function PdoIndicators({ pdoindex, category, index }) {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className='mb-2'>
            <div onClick={() => { setExpanded(!expanded) }} className='fw-bold p-3  d-flex justify-content-between shadow-sm'>

                <h6 >{category.title}</h6>
                <span className=''>{expanded ? <ArrowDropDown /> : <ArrowRight />}</span>
            </div>

            <div className={expanded ? 'row justify-content-start  p-4' : 'd-none'}>

                {category.indicators.map((indicator, index) => {
                    return (
                        <div key={index} className="col-5 rounded-4 p-4 me-5 mb-5 d-flex align-items-center  bg-success text-white">
                            {/* {pdo.title === "Performance-Based Conditions" && `PBC ${index + 1}`} */}
                            <p className='col-8 fs-6' >
                                {indicator.title}
                            </p>
                            <div className='col-4 fw-bold'>

                                <a href={`/pdo-reports/${indicator.id}`} className='btn btn-light text-white btn-md'>View</a>

                            </div>

                        </div>
                    )

                })}

            </div>

        </div>
    )
}
