
import { CChart } from "@coreui/react-chartjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CourseFeedback = () => {
    const [date, setDate] = useState(' ');
    const { id, center_id } = useParams();
    const [dets, setDets] = useState(center_id);
    const [feedback, setFeedback] = useState('')

    useEffect(() => {

        var formdata = new FormData();
        formdata.append("center_id", center_id);
        formdata.append("course_id", id);


        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/commentsPercentageCourseBased`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setFeedback(result)
                console.log(result)
            }
            )
            .catch(error => console.log('error', error));
    }, [])


    // let dates = getMonth()
    return (<div className="">
        <div className="p-2">
            <p className=" fw-bold text-center">Student Feedback for course</p>
            <div className="col-6 m-auto">
                {
                    feedback != '' && (
                        <CChart
                            type="doughnut"
                            data={{
                                labels: ['Very Satisfied', 'Satisfied', 'Dissatisfied', 'Very Disatisfied', "Haven't Rated"],
                                datasets: [
                                    {
                                        backgroundColor: ['#41B883', '#6EEEBB', 'yellow', '#DD1B16', 'grey'],
                                        data: [feedback.very_sat, feedback.sat, feedback.dissat, feedback.very_dissat, feedback.not_rated],
                                    },
                                ],
                            }}
                        />
                    )
                }

            </div>

        </div>
    </div>);
}

export default CourseFeedback;