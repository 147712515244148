import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function IvaTotalCards({ id }) {

    const [totalStudent, SettotalStudent] = useState("");
    const [totalLecturer, setTotalLecturer] = useState("");
    const [totalFaculties, setTotalFaculties] = useState("");
    const [courseLunched, setCourseLunched] = useState("");
    const [partners, setPartners] = useState("");

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            let url = `${process.env.REACT_APP_BASE_URL}`;

            try {
                const { data: response } = await axios.get(url + `/api/getcenterdetails/${id}`);
                SettotalStudent(response.result.students);
                setTotalLecturer(response.result.faculties)
                setTotalFaculties(response.result.certificates)
                setCourseLunched(response.result.courses)
                setPartners(response.result.partners)

                // console.log(response.result);
                // setCenterName(response.result.center_name)


            } catch (error) {
                console.error(error.message);
            }

        }
        fetchData();
    }, []);
    return (
        <div className='d-flex justify-content-between mt-4'>
            <div className="p-4 bg-success shadow text-light rounded-3   text-center" >
                <div className='fw-bold '>Total Students:  {totalStudent}</div>
            </div>

            <div className="p-4 bg-success shadow text-light rounded-3   text-center">
                <div className='fw-bold '>Total Courses Launched: {courseLunched}</div>
            </div>

            <div className="p-4 bg-success shadow text-light rounded-3   text-center">
                <div className='fw-bold '>Total Faculties: {totalLecturer}</div>
            </div>

            <div className="p-4 bg-success shadow text-light rounded-3   text-center" onClick={() => {
                // window.location.href = `/center/certificates/${id}`
            }}>
                <div className='fw-bold '>Total Certificates: {totalFaculties}</div>
            </div>
            <div className="p-4 bg-success shadow text-light rounded-3   text-center" onClick={() => {
                window.location.href = `/iva/partners/${id}`
            }}>
                <div className='fw-bold '>Total Partners: {partners}</div>
            </div>
        </div>
    )
}
