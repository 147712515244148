import styled from "styled-components";

export const StyledTop = styled.div`
    padding: auto;
    box-sizing: border-box;
    border-radius: 10px;
    margin: auto 20px;
    

    h1 {
        font-weight: 500;
        font-size: 15px;
        line-height: 1;
    }

    p {
        font-size: 25px;
       
    }

    .tops{
        width:100%;
        height:150px;
        display:flex;
        margin:0.1rem;
        margin-top:2rem;
    }
    .top{
        width:30%;
        height: 18rem;
        display:flex;
        flex-direction: column;
        background-color:#fff;
        padding:5px;
        margin: 0.5rem;
        box-shadow: 0px 1px 4px #E5E9F2;
        border-radius: 5px;
        margin-right:4%;
    }

    .top2{
        width:70%;
        height: 18rem;
        display:flex;
        flex-direction: column;
        background-color:#fff;
        padding:5px;
        margin: 0.5rem;
        box-shadow: 0px 1px 4px #E5E9F2;
        border-radius: 5px;
    }

    .topTitle{
        height: 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #31394D;
        margin:10px;
    }

    .figure{
        position: absolute;
        height: 17px;
        padding-top: 15px;
        padding-left: 80px;
        margin-top: 3%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 16px;
        color: #748AA1;

    }

    .partner{
        width:100;
        display:flex;
        flex-direction: row;
        margin:10px;
    }

    .partnerImage{
        padding:5px;
    }

    .partnerName{
        padding:5px;
    }

    #page-header {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background: blue; */
        width: 100%;
    }
    
    #page-header>.header-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        /* background: red; */
    }
    
    nav {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        /* background: red; */
    }
    
    .topbar {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 1rem 1rem 1rem 1.5rem;
        /* background: red; */
    }
    
    .title-side-menu-toggl {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        /* background: red; */
    }
    
    .user-account {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 5px;
        /* background: red; */
    }
    
    .user-account-pic {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 50px;
        background: green;
        color: white;
        border: 0px;
    }
    
    .user-account-name {
        font-size: 16px;
    }
    
    
    
    
    #page-header>.head {
        display: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #F9F9F9;
    }
    
    
    
    
    
    /* -----------------------------dashboard-NAV CSS -----------------------------*/
    
    /* -----------------------------dashboard-Header CSS -----------------------------*/
    
    
    
    
    #breadcrumbContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-family: 'Roboto';
        font-style: normal;
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #31394D;
        /* background: red; */
    }
    
    #breadcrumbRight {
        display: flex;
        align-items: center;
        /* background: green; */
        margin-top: 10px;
        gap: 6rem;
    }
    
    
    .userFirstName {
        height: 17px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #748AA1;
    
    }
    
    .welcomeMsg {
        font-size: 25px;
        line-height: 23px;
    
    }
    
    
    
    /* -----------------------------dashboard-sidbar -----------------------------*/
    #side-bar {
        background: #1B3A1B;
        height: 100%;
        width: 100%;
    }
    
    .side-menu>li>h3,
    .side-menu__label {
        color: white;
        gap: 5rem;
    }
    /* .side-app{   background: red; */
        /* width: 2opx; */
    
    .main-sidemenu {
        display: flex;
        flex-direction: column;
        color: white;
        background: #1B3A1B;
        height: 100%;
        gap: 5rem;
    }
    
    .side-bar-icons{
    font-size: 18px;
    }
    #header-icons{
    color: rgb(112, 231, 14);
    font-size: 38px;
    /* background: red; */
    width: 20pz;
    }
    .fitersate{color:white;}
    .slide >a{
        gap: 2rem;
    }
    /* -----------------------------dashboard-sidebar -----------------------------*/
    
    
    /* Row 1 */
    #row1 {
        position: relative;
        top: 8.5rem;
        width: 100%;
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        /* background: red; */
    }
    
    .col {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #31394D;
    }
    
    #row1-col1,
    #row1-col2,
    #row1-col3,
    #row1-col4 {
        min-height: 150.32px;
        box-shadow: 0px 1px 1px #E5E9F2;
        border-radius: 5px;
    }
    
    /* Row 1 End */
    
    /* Row 2 */
    #row2 {
        position: relative;
        top: 8.5rem;
    }
    
    #row2-card-header {
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
    }
    
    .top-sales-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0;
        padding-top: 0;
        width: 100%;
    }
    
    .peercent-no {
        display: flex;
        justify-content: space-between;
        padding-top: 0;
        width: 100%;
    }
    
    #row2-body {
        margin: 0 0 2rem 0;
    }
    
    .record-bars {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: white;
        width: 100%;
        height: 184px;
        padding: 0 0 15px 0;
    }
    
    .record {
        display: flex;
        align-items: center;
        width: 100%;
        /* background: blue; */
    }
    
    .progress-bar {
        display: flex;
        width: 40%;
        height: 10px;
    }
    
    .progress-percent {
        font-size: 16px;
    }
    
    #record1>.progress-bar {
        width: 63%;
        background: #B558F6;
    }
    
    #record2>.progress-bar {
        width: 47%;
        background: #29CB97;
    }
    
    #record3>.progress-bar {
        width: 52%;
        background: #FEC400;
    }
    
    #record4>.progress-bar {
        width: 81%;
        background: #4072EE;
    }
    
    .bn-hr {
        position: absolute;
        left: 0;
        height: 1px;
        width: 100%;
        background: #EBEDF4;
        margin: 0;
        padding: 0;
    }
    
    .margin-line {
        height: 100%;
        width: 1px;
        background: #EBEDF4;
    }
    
    .record-percent-margins {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 50px 0 23px;
        /* background: rgb(77, 74, 74); */
        width: 100%;
        height: 12.9rem;
    }
    
    .record-summary {
        position: relative;
        /* background: green; */
        width: 100%;
        height: 184px;
        margin-top: 1px;
    }
    
    .summary-lsit {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        justify-content: space-between;
        background: white;
        width: 100%;
        height: 184px;
        padding: 20px 0 1px 0;
    }
    
    .item {
        display: flex;
        align-items: center;
        gap: 2rem;
        width: 100%;
        height: 2rem;
        /* background: red; */
    }
    
    .item-icon {
        height: 20px;
        width: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 4px solid #4072EE;
        border-radius: 10px;
    }
    
    .item-percent {
        font-size: 16px;
    }
    
    #item1>.item>.item-icon {
        border: 4px solid #4072EE;
    }
    
    #item2>.item>.item-icon {
        border: 4px solid #B558F6;
    }
    
    #item3>.item>.item-icon {
        border: 4px solid #FEC400;
    }
    
    #item4>.item>.item-icon {
        border: 4px solid #29CB97;
    }
    
    /* Active Users */
    
    /* Active Users */
    
    /* Row 2 End */
    
    /* Row 3 */
    #row3 {
        position: relative;
        top: 8.5rem;
        display: flex;
        justify-content: space-between;
        padding: 42px 90px 25px 90px;
        background: white;
    }
    
    #row3-col1,
    #row3-col2 {
        min-height: 150.32px;
        min-width: 45%;
    }
    
    #row3-col1-col-row1,
    #row3-col1-col-row1 {
        display: flex;
        align-items: flex-start;
    
        justify-content: space-between;
        /* background: red; */
        min-height: 150px;
        padding: 0 20% 0 10%
    }
    
    #row3-col1-col-row1>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #31394D;
    }
    
    select {
        border: none;
    }
    
    option {
        border: none;
    }
    
    /* Row 3 End */
    
    /* footer */
    #footer {
        margin-top: 20px;
    }
    
    /* footer*/
    
    #page-title {
        display: flex;
        justify-content: space-between;
        background: gray;
    }
    @media screen and (max-width:700px) {
        #page-header>.header-nav {
            display: flex;
        }
    
        #page-header>.head {
            display: none;
        }
    
        #row1,
        #row2,
        #row3 {
            top: 11.3rem;
        }
    
        #side-menu {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    
        #side-menu>.list {
            margin-top: 5rem;
        }
    
        #row3 {
            display: flex;
        }
    
        .footer {
            height: fit-content;
        }
    
        #row3-col1-row1,
        #row3-col2 {
            flex-direction: column;
            align-items: center;
            min-width: 200px;
            justify-content: space-between;
        }
    }
    
    @media screen and (min-width:700px) {
        #page-header>.header-nav {
            display: none;
        }
    
        #page-header>.head {
            display: flex;
        }
        #side-menu {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    
        #side-menu>.list {
            margin-top: 5rem;
            color: white;
        }
    }
    
`