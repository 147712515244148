import React, { useEffect, useState } from "react";
import axios from 'axios'
import Nav from './../Components/Nav'
import PageTitle from './../Components/PageTitle'
import { StyledMainBody } from './../Components/Styles/MainBody.styles';
import SideNav from './../Components/SideNav';

import GetAllCenters1 from './../Components/GetAllCenters1'



const Review = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

    const [allDlis, SetAllDlis] = useState([])

    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");

    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = "https://stockmgt.gapaautoparts.com/api/GetAllDLi";

            try {
                const { data: response } = await axios.get(url);
                SetAllDlis(response.result);
               console.log(response.result);
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    return (
        <>
            <SideNav />
            <StyledMainBody>
                <Nav />
                
                    <div className="card-header border-bottom m-5 mt-9" id="row2-card-header">
                        <div className="top-sales-header">
                            <h1 className="title">DLI Verification Report</h1>
                            <div>

                                {/* <button className="btn btn-success btnAdd" onClick={handleShow}>
                            <FaPlus />Add New Center
                        </button> */}
                            </div>
                        </div>
                    </div>
                    <div className="table">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th className="w-5">#</th>
                                    <th className="w-20">DLI Name</th>
                                    <th className="w-20">Description</th>
                                    <th className="w-10">Data source/ Agency</th>
                                    <th className="w-10">Verification Entity</th>
                                    <th className="w-40">Procedure</th>
                                    <th className="w-5"></th>
                                </tr>
                                
                            </thead>
                            <tbody>
                            {
                            allDlis?.length > 0 && (
                                allDlis.map((row, key) => (
                                    <>
                                        <tr key={key}>
                                            <td className="w-5">{key + 1}</td>
                                            <td className="w-20">{row.dli_name}</td>
                                            <td className="w-20">{row.dli_name}</td>
                                            <td className="w-10">{row.dli_name}</td>
                                            <td className="w-10">{row.dli_name}</td>
                                            <td className="w-40">{row.dli_name}</td>
                                            <td className="w-5">{row.dli_name}</td>
                                           
                                        </tr>
                                    </>
                                ))
                            )
                        }
                            
                            </tbody>
                        </table>
                    </div>
               
            </StyledMainBody>
        </>

    );
}

export default Review;
