
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

function SettingsIndex() {
    const [display, SetDisplay] = useState('profile');
    const username = JSON.parse(localStorage.getItem('admin'))
    const [users, setUsers] = useState('')
    const [correct, setCorrect] = useState(false)
    const [profile, setProfile] = useState({
        name: username.name,
        email: username.email,
        Password: '',
    })
    // console.log(users)
    const [newuser, SetUser] = useState({
        name: '',
        email: ' ',
        password: ' ',
        type: 'View',
    })
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getadminusers/${username.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setUsers(result.data)
                // console.log(result)
            })
            .catch(error => console.log('error', error));

    }, []);
    function userchange() {
        window.location.assign('/settings/adduser');
        // SetDisplay('user');
    }
    function profileChange() {
        // SetDisplay('profile');
        window.location.assign('/settings');

    }
    function con_Password(e) {
        let query = e.target.value

        if (query != profile.Password) {
            setCorrect(false)
        } else {
            setCorrect(true)

        }
    }
    function editProfile(e) {

        e.preventDefault();
        var formdata = new FormData();
        formdata.append("email", profile.email);
        formdata.append("id", username.id);
        formdata.append("name", profile.name);
        formdata.append("password", profile.Password);


        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/updateProfile`, requestOptions)
            .then(response => response.json())
            .then(result => {

                localStorage.setItem("admin", JSON.stringify(result.data));
                // console.log(result)
                window.location.reload()
            }
            )
            .catch(error => console.log('error', error));
    }
    function handleReg(e) {
        e.preventDefault()

        var formdata = new FormData();
        formdata.append("name", newuser.name);
        formdata.append("email", newuser.email);
        formdata.append("password", newuser.password);
        formdata.append("admin_id", username.id);
        formdata.append("type", newuser.type);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/createAdminUser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire({
                    icon: 'success',
                    title: 'user added',
                    text: 'user added ',
                    timer: 1000,

                })
                // window.location.assign('/settings')

                window.location.reload()
            }
            )
            .catch(error => console.log('error', error));
    }
    function deleteuser(id) {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var requestOptions = {
                    method: 'get',
                    // body: formdata,
                    redirect: 'follow'
                };
                fetch(`${process.env.REACT_APP_BASE_URL}/api/deleteAdminUser/${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        // localStorage.setItem("admin", JSON.stringify(result.data));
                        // console.log(result)
                        Swal.fire(
                            'Deleted!',
                            'User has been deleted.',
                            'success'
                        )
                        window.location.reload()
                    }
                    )
                    .catch(error => console.log('error', error));

            }
        })


    }
    return (
        <>
            <div className='my-5 py-4 row shadow'>
                <div className='col-2'>
                    <ul>
                        <li className={display == 'profile' ? 'border-end rounded-2 border-2 border-primary p-2' : ' '} onClick={profileChange}>
                            Profile Settings
                        </li>
                        <li className={display == 'profile' ? ' ' : 'border-end rounded-2 border-2 border-primary p-2'} onClick={userchange}>
                            User Permission
                        </li>
                    </ul>
                </div>
                <div className='col-9'>

                    <div className={
                        display == 'profile' ? ' ' : 'd-none'
                    }>
                        {/* Profile Div */}
                        <form className='' onSubmit={editProfile}>
                            <div className='mb-3'>
                                <label>Name</label>
                                <input value={profile.name} onChange={(e) => setProfile(
                                    { ...profile, name: e.target.value })} type="text" className='form-control' placeholder='enter name' />
                            </div>
                            <div className='mb-3'>
                                <label>Email Address</label>
                                <input value={profile.email} onChange={(e) => setProfile(
                                    { ...profile, email: e.target.value })} type="email" className='form-control' placeholder='enter email address' />
                            </div>
                            <div className='mb-3'>
                                <label>Old Password -- leave blank if you are not changing--</label>
                                <input type="text" className='form-control' placeholder='enter old password' />
                            </div>
                            <div className='mb-3'>
                                <label>New Password</label>
                                <input type="password" onChange={(e) => setProfile(
                                    { ...profile, Password: e.target.value })} className='form-control' placeholder='enter new password' />
                            </div>
                            <div className='mb-3'>
                                <label>Confirm New Password</label>
                                <input type="password" onChange={con_Password} className={correct != true ? 'form-control bg-danger' : 'form-control bg-success'} placeholder='confirm password' />
                            </div>

                            <button className='btn btn-primary shadow' >Save</button>
                        </form>
                    </div>

                    <div className={
                        display == 'user' ? ' ' : 'd-none'
                    }>
                        {/* User Permission */}

                        <div className='row'>

                            <div className='col-3'>
                                <h5>Add new user</h5>
                                <form className='' onSubmit={handleReg}>
                                    <div className='mb-3'>
                                        <label>Name</label>
                                        <input onChange={(e) => SetUser(
                                            { ...newuser, name: e.target.value })} type="text" className='form-control' required placeholder='enter name' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Email</label>
                                        <input type="email" onChange={(e) => SetUser(
                                            { ...newuser, email: e.target.value })} className='form-control' required placeholder='enter email address' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Password</label>
                                        <input type="text" onChange={(e) => SetUser(
                                            { ...newuser, password: e.target.value })} className='form-control' required placeholder='enter your password' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Type</label>
                                        <select type="text" onChange={(e) => SetUser(
                                            { ...newuser, type: e.target.value })} className='form-control' required placeholder='select user type' >
                                            <option selected value="View" key="">View</option>
                                            <option value="Edit" key="">Edit</option>
                                            <option value="supper" key="">Supper</option>
                                        </select>
                                    </div>

                                    <button className='btn btn-primary'>Save</button>
                                </form>
                            </div>



                            <div className='col-9 table-responsive'>
                                <h5> Admin Users</h5>

                                <table className='table table-sm table-hover'>
                                    <thead>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Permission type
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.isArray(users) && users.map((row, key) => {
                                                return (
                                                    <tr key="">
                                                        <td>
                                                            {row.name}
                                                        </td>
                                                        <td>
                                                            {row.email}
                                                        </td>
                                                        <td>
                                                            {row.type}
                                                        </td>

                                                        <td>
                                                            <div className='btn-group'>
                                                                <button className='btn   btn-sm btn-primary'>Edit</button>
                                                                <button onClick={() => deleteuser(row.id)} className=' btn-sm btn btn-danger'>Delete</button>
                                                            </div>


                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default SettingsIndex
