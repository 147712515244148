import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { useRouteError, useParams, Link } from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaUsers, FaPlus, FaHome, FaSignOutAlt, FaCog, FaFilePrescription, FaComments, FaShoppingBasket, FaAddressBook } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { StyledCards } from './Styles/cards';
import axios from 'axios'

import { StyledPoints } from "./Styles/Points.styles";
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";

import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";



import AddCenter from './AddCenter'
import IvaCenter from "../Pages/IvaCenter";
import CentersInfo from "./CentersInfo";
import AllCert from "./AllCertificates";
import TotalCards from "./totalCards";

const CentersCertificates = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [centerName, setCenterName] = useState("");

    const handleClose = () => setShow(false);
    const { id } = useParams();

    const [courses, setCourses] = useState("");


    //get the last lunch programme course by the center









    return (
        <>
            <TotalCards id={id} />
            <div className="mt-4">
                <AllCert />

            </div>

        </>

    );
}

export default CentersCertificates;


