import React, { useEffect, useState } from "react";
import axios from 'axios'
import SideNav from "../Components/SideNav";
import Nav from "../Components/Nav";
import SettingsIndex from "../Components/Settings/SettingsIndex";




const Activity = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])
    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/getlogsactivity/admin`, requestOptions)
            .then(response => response.json())
            .then(result => setData(result.logs))
            .catch(error => console.log('error', error));
    }, [])

    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }



    return (
        <>

            <div className="row ">
                <div className="col-1">
                    <SideNav />
                </div>
                <div className="col-11">
                    <Nav />

                    <div className="bg-light my-5 mx-3 p-3 shadow">

                        <div>
                            <h6 className="fw-bold">
                                Activity Logs
                            </h6>
                        </div>

                        <div>
                            {
                                Array.isArray(data) && data.map((item, index) => {
                                    return (
                                        <p key={index}>{item.username} --- {item.activity_carried} -- {item.created_at}</p>
                                    )
                                })
                            }
                        </div>

                    </div>
                    {/* <Trainees /> */}

                </div>
            </div>
        </>

    );
}

export default Activity;
