import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteError
} from "react-router-dom";


import { FaPlus, FaHome, FaEye, FaTrash, FaEdit, FaComments, FaSearch, FaRecycle } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { StyledPageTitle } from "../../Components/Styles/pages.style";

const GetAllDashboards = () => {
    const user_type = JSON.parse(localStorage.getItem('admin'))
    const [show, setShow] = useState(false);
    function handleShow() {
        setShow(true)
        setEdit(false)
        setAddedUser({
            name: '',
            email: '',
            password: '',
            type: ''
            ,
        })
    };
    const [loading, setLoading] = useState('');
    const [dashboards, setDashboards] = useState('');
    const [recentProgrammeLunched, SetRecentProgrammeLunched] = useState(null)
    const [loader, setLoader] = useState(false)
    const [notify, setNotify] = useState('')
    const [main_pass, setMainPass] = useState('');
    const [confirm_pass, setConfirmPass] = useState('');
    const [selected, setSelected] = useState('');
    const [edit, setEdit] = useState(false);
    const [added_user, setAddedUser] = useState({
        'name': '',
        'email': '',
        'password': '',
        'type': '',
    })
    const [updated, setUpdated] = useState('');
    const handleClose = () => setShow(false);

    console.log(added_user.password)
    function confirmpassword(pass) {

        if (pass === main_pass) {
            setNotify('Password Correct');
            setAddedUser({ ...added_user, password: pass });
        } else {
            setNotify('Password do not match');
        }
    }

    useEffect(() => {
        let token = localStorage.getItem("token");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/all_dashboard_users`, requestOptions)
            .then(response => response.json())
            .then(result => setDashboards(result))
            .catch(error => console.log('error', error));
    }, [updated]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", added_user.name);
        urlencoded.append("email", added_user.email);
        urlencoded.append("type", added_user.type);
        urlencoded.append("password", added_user.password);
        edit && urlencoded.append('id', selected)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = edit != true ? await fetch(`${process.env.REACT_APP_BASE_URL}/api/add_dashboard_user`, requestOptions) :
            await fetch(`${process.env.REACT_APP_BASE_URL}/api/update_dashboard_users`, requestOptions);
        const data = await response.json()
        if (response.status == 200) {
            setLoader(false);
            Swal.fire(
                'Success!',
                'Dashboard User Added Successfully!',
                'success'
            )

            setUpdated(Math.random())
            handleClose();
        }

    }
    function handleFilter(e) {
        const query = e.target.value;

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/search_dash_users/${query}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setDashboards(result)
            })
            .catch(error => console.log('error', error));
    }
    const handleDelete = (index, e, id) => {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/delete_dashboard_users/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire(
                    'Success!',
                    'Dashboard User Deleted Successfully!',
                    'success'
                )
                setUpdated(Math.random())
            })
            .catch(error => console.log('error', error));
    }
    const handleReset = (index, e, id) => {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/resetpassword/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire(
                    'Success!',
                    'Password Reset Successfully!',
                    'success'
                )
                window.location.reload()

            })
            .catch(error => console.log('error', error));
    }
    return (
        <StyledPageTitle>
            <div className="card-header border-bottom m-5" id="row2-card-header">
                <div className="d-flex w-100 justify-content-between">
                    <div>
                        <h1 className="title">Manage Dashboards Users</h1>
                        {
                            user_type.type != 'View' &&
                            <div>
                                <button className="btn btn-success btnAdd" onClick={handleShow}>
                                    <FaPlus />Add New Dashboards Users
                                </button>
                            </div>
                        }
                    </div>


                    <div className="align-items-center">
                        <Form.Group className=" " controlId="formBasicPassword">

                            <input className="form-control" onChange={handleFilter} type="text" placeholder="--search for centers---" />

                            <a href="/manage-dashboards" className="btn btn-success m-2 w-100" >
                                <FaSearch />View All Dashboards
                            </a>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Type</th>
                            <th>Date Added</th>
                            <th></th>
                            <th></th>
                        </tr>
                        {
                            Array.isArray(dashboards) && (
                                dashboards.map((row, key) => (
                                    <>

                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.email}</td>
                                            <td>{row.type == 'Women_Affairs' ? "Women Affairs" : row.type == "BPP" ? "BPP" : row.type == "Environment" ? "Environment" : "IVA"}</td>
                                            <td>{row.created_at}</td>
                                            {
                                                user_type.type != 'View' &&
                                                <>
                                                    <td>
                                                        <button
                                                            className="btn btn-sm btn-info m-2"
                                                            onClick={(e) => {
                                                                setSelected(row.id)
                                                                setEdit(true);
                                                                setAddedUser({
                                                                    email: row.email,
                                                                    name: row.name,
                                                                    type: row.type,
                                                                })
                                                                setShow(true);
                                                            }}
                                                        >
                                                            <FaEdit /> EDIT
                                                        </button>
                                                    </td>
                                                    <td>

                                                        <button className="btn btn-sm btn-danger m-2" onClick={e => handleDelete(key, e, row.id)}>
                                                            <FaTrash />Delete
                                                        </button>

                                                    </td>
                                                    {/* <td>
                                                        <button className="btn btn-sm btn-danger m-2" onClick={e => handleReset(key, e, row.id)}>
                                                            <FaRecycle />Reset Password
                                                        </button>
                                                    </td> */}
                                                </>
                                            }
                                        </tr>
                                    </>
                                ))
                            )
                        }


                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>

            <>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{edit != true ? 'Add New External Dashboard User' : 'Edit External Dashboard User'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            loader ? <CircularProgress /> : ""
                        }
                        <div className="mb-2">{notify}</div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label>Name</label>
                                <input type="text" required onChange={(e) => {
                                    setAddedUser({ ...added_user, name: e.target.value })
                                }} value={added_user.name} className="form-control" placeholder="enter name of dashboard user" />
                            </div>
                            <div className="mb-3">
                                <label>Email</label>
                                <input type="email" onChange={(e) => {
                                    setAddedUser({ ...added_user, email: e.target.value })
                                }} required value={added_user.email} className="form-control" placeholder="enter email address" />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input type="password" onChange={(e) => {
                                    setMainPass(e.target.value)
                                }} className="form-control" {...edit != true && 'required'} placeholder="enter password for user" />
                            </div>
                            <div className="mb-3">
                                <label>Confirm Password</label>
                                <input type="password" {...edit != true && 'required'} onChange={(e) => confirmpassword(e.target.value)} className="form-control" placeholder="confirm password" />
                            </div>
                            <div className="mb-3">
                                <label>Type</label>
                                <select onChange={(e) => {
                                    setAddedUser({ ...added_user, type: e.target.value })
                                }} value={added_user.type} className="form-select">
                                    <option selected value="" key="">--Select Type--</option>
                                    <option selected value="Iva" key="">IVA</option>
                                    <option value="Women_Affairs" key="">Women Affairs</option>
                                    <option value="BPP" key="">BPP</option>
                                    <option value="Environment" key="">Environment</option>

                                </select>
                            </div>
                            <button type="submit" class="btn btn-success" >
                                Save Changes
                            </button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>

        </StyledPageTitle>
    );
}

export default GetAllDashboards;


