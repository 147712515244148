import { CommentSharp, Directions, GroupSharp, Home, LogoutRounded, Note, Person2, Search, Settings } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React, { useState } from 'react';


import { Tooltip } from 'react-tooltip'
import { StyledSideNav } from '../../Styles/SideNav.styles';

const SideNavIva = () => {

    const [isLoggedIn, setisLoggedIn] = useState(null);

    const logOut = () => {
        setisLoggedIn(false);
        localStorage.clear();
        window.location.href = "/";
    };


    return (

        <>

            <div ><a href="/dashboardIva" title="Home" id="home" data-tooltip-content="Mome"><Person2 className='icons' /></a></div>

            <div><a href="/dashboardIva" title="Home" id="home" data-tooltip-content="Mome"><Home className='icons' /></a></div>
            {/* <div><a href="/centers" title="Manage Centers"><Directions className='icons' /></a></div>
                <div><a href="/programmes" title='Manage Programmes'><Note className='icons' /></a></div>
                <div><a href="/verification" title="Manage DLI Verification"> <Search className='icons' /></a></div>

                <div><a href="/users" title="Manage Users"><GroupSharp className='icons' /></a></div>
                <div><a href="/messages" title="Manage Messages"><CommentSharp className='icons' /></a></div>
                <div><a href="/settings" title="Operations"><Settings className='icons' /></a></div> */}


            <div className='lastLi'><a href="/"><LogoutRounded className='icons' /></a></div>

        </>


    );
}

export default SideNavIva;
