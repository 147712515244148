import styled from "styled-components";

export const StyledSideNav = styled.nav `
    flex-basis: 5%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 150px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(27, 58, 27, 1);
    position:fixed;

    img {
        width: 50px;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        height: 50px;
    }

    li{
        list-style: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }


    a {
        color: #fff;
        font-size: 60px;
        width: 20px;
        height: 20px;
        display: inline-flex;
        opacity: 0.85;
    }

    .lastLi{
        border-bottom: none;
    }


      
`