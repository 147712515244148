
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

function SettingsIndexAdd() {
    const [display, SetDisplay] = useState('user');
    const username = JSON.parse(localStorage.getItem('admin'))
    const [users, setUsers] = useState('')
    const [editor, setEditor] = useState(false);
    const [ident, setId] = useState('');
    const [update, setUpdate] = useState('');
    const [newuser, SetUser] = useState({
        name: '',
        email: ' ',
        password: ' ',
        type: 'View',
    })
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getadminusers/${username.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setUsers(result.data)
                // console.log(result)
            })
            .catch(error => console.log('error', error));

    }, [update]);
    function userchange() {
        window.location.assign('/settings/adduser');
        // SetDisplay('user');
    }
    function profileChange() {
        // SetDisplay('profile');
        window.location.assign('/settings');

    }
    function handleReg(e) {
        e.preventDefault()

        var formdata = new FormData();
        formdata.append("name", newuser.name);
        formdata.append("email", newuser.email);
        formdata.append("password", newuser.password);
        formdata.append("admin_id", username.id);
        formdata.append("type", newuser.type);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/createAdminUser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire({
                    icon: 'success',
                    title: 'user added',
                    text: 'user added ',
                    timer: 1000,

                })
                // window.location.assign('/settings')
                setUpdate(Math.random())
                // window.location.reload()
            }
            )
            .catch(error => console.log('error', error));
    }
    function handleedit(e) {
        e.preventDefault()

        var formdata = new FormData();
        formdata.append("name", newuser.name);
        formdata.append("email", newuser.email);
        formdata.append("password", newuser.password);
        formdata.append("admin_id", username.id);
        formdata.append("user_id", ident);
        formdata.append("type", newuser.type);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/updateAdminUser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire({
                    icon: 'success',
                    title: 'User Update',
                    text: 'User Updated Successfully',
                    timer: 1000,

                })
                // window.location.assign('/settings')
                setUpdate(Math.random())
                // window.location.reload()
            }
            )
            .catch(error => console.log('error', error));
    }
    function suspend(id) {

        Swal.fire({
            title: 'Are you sure?',
            text: "Kindly confirm this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Suspend!'
        }).then((result) => {
            if (result.isConfirmed) {
                var requestOptions = {
                    method: 'get',
                    // body: formdata,
                    redirect: 'follow'
                };
                fetch(`${process.env.REACT_APP_BASE_URL}/api/suspenduser/${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        // localStorage.setItem("admin", JSON.stringify(result.data));
                        // console.log(result)
                        Swal.fire(
                            'Suspended!',
                            'User has been suspended.',
                            'success'
                        )
                        // window.location.reload()
                        setUpdate(Math.random())
                    }
                    )
                    .catch(error => console.log('error', error));

            }
        })


    } function unsuspend(id) {

        Swal.fire({
            title: 'Are you sure?',
            text: "Kindly confirm this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Unsuspend!'
        }).then((result) => {
            if (result.isConfirmed) {
                var requestOptions = {
                    method: 'get',
                    // body: formdata,
                    redirect: 'follow'
                };
                fetch(`${process.env.REACT_APP_BASE_URL}/api/unsuspenduser/${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        // localStorage.setItem("admin", JSON.stringify(result.data));
                        // console.log(result)
                        Swal.fire(
                            'Unsuspended!',
                            'User has been unsuspended.',
                            'success'
                        )
                        // window.location.reload()
                        setUpdate(Math.random())
                    }
                    )
                    .catch(error => console.log('error', error));

            }
        })


    }
    function deleteuser(id) {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var requestOptions = {
                    method: 'get',
                    // body: formdata,
                    redirect: 'follow'
                };
                fetch(`${process.env.REACT_APP_BASE_URL}/api/deleteAdminUser/${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        // localStorage.setItem("admin", JSON.stringify(result.data));
                        // console.log(result)
                        Swal.fire(
                            'Deleted!',
                            'User has been deleted.',
                            'success'
                        )
                        // window.location.reload()
                        setUpdate(Math.random())
                    }
                    )
                    .catch(error => console.log('error', error));

            }
        })


    }
    function edituser(itemId, index) {
        setEditor(true);
        setId(itemId);
        SetUser({
            name: users[index].name,
            email: users[index].email,
            type: users[index].type,
        })
    }
    return (
        <>
            <div className='my-5 py-4 row shadow'>
                <div className='col-2'>
                    <ul>
                        <li className={display == 'profile' ? 'border-end rounded-2 border-2 border-primary p-2' : ' '} onClick={profileChange}>
                            Profile Settings
                        </li>
                        <li className={display == 'profile' ? ' ' : 'border-end rounded-2 border-2 border-primary p-2'} onClick={userchange}>
                            User Permission
                        </li>
                    </ul>
                </div>
                <div className='col-9'>

                    <div className={
                        display == 'user' ? ' ' : 'd-none'
                    }>
                        {/* User Permission */}

                        <div className='row'>

                            <div className='col-3'>
                                {editor == true ? <h5>Edit user</h5> : <h5>Add new user</h5>}

                                <form className='' onSubmit={editor == true ? handleedit : handleReg}>
                                    <div className='mb-3'>
                                        <label>Name</label>
                                        <input onChange={(e) => SetUser(
                                            { ...newuser, name: e.target.value })} value={newuser.name} type="text"
                                            className={editor == true ? 'form-control border-danger border ' : 'form-control'}
                                            required placeholder='enter name' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Email</label>
                                        <input type="email" onChange={(e) => SetUser(
                                            { ...newuser, email: e.target.value })} value={newuser.email} className={editor == true ? 'form-control border-danger border ' : 'form-control'} required placeholder='enter email address' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Password</label>
                                        <input type="text" onChange={(e) => SetUser(
                                            { ...newuser, password: e.target.value })} className={editor == true ? 'form-control border-danger border ' : 'form-control'} required placeholder='enter your password' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Type</label>
                                        <select type="text" onChange={(e) => SetUser(
                                            { ...newuser, type: e.target.value })} value={newuser.type} className={editor == true ? 'form-control border-danger border ' : 'form-control'} required placeholder='select user type' >
                                            <option selected value="View" key="">View</option>
                                            <option value="Edit" key="">Edit</option>
                                            <option value="supper" key="">Super</option>
                                            {/* <option value="view_only" key="">IVA</option> */}

                                        </select>
                                    </div>

                                    {editor == true ? <button className='btn btn-primary'>Edit</button> : <button className='btn btn-primary'>Save</button>}


                                </form>
                            </div>



                            <div className='col-9 table-responsive'>
                                <h5> Admin Users</h5>

                                <table className='table table-sm table-hover'>
                                    <thead>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Permission type
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.isArray(users) && users.map((row, key) => {
                                                return (
                                                    <tr key="">
                                                        <td>
                                                            {row.name}
                                                        </td>
                                                        <td>
                                                            {row.email}
                                                        </td>
                                                        <td>
                                                            {row.type == 'supper' ? 'Super User' : row.type == 'View' ? 'View Access' : 'Edit Access'}
                                                        </td>

                                                        <td>
                                                            <div className='btn-group'>
                                                                <button onClick={() => edituser(row.id, users.indexOf(row))} className='btn   btn-sm btn-primary'>Edit</button>
                                                                {
                                                                    row.status != 'suspend' ? <button onClick={() => suspend(row.id)} className=' btn-sm btn btn-danger'>Suspend</button> : <button onClick={() => unsuspend(row.id)} className=' btn-sm btn btn-success'>Unsuspend</button>
                                                                }
                                                                <button onClick={() => deleteuser(row.id)} className=' btn-sm btn btn-danger'>Delete</button>
                                                            </div>


                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default SettingsIndexAdd
