import React, { useEffect, useState } from 'react'

export default function DLiSumReports({ tracki, id }) {
    const [dlisum, setDliSum] = useState({
        'three_months': '',
        'four_months': '',
        'five_months': '',
        'six_months': '',
        'seven_months': '',
        'eight_months': '',
        'total_faculty': '',
        'faculty_phd': '',
        'faculty_regional': '',
        'faculty_international': '',
        'faculty_industrial': '',
        'remote_learning': '',
    })
    useEffect(() => {
        // function tracksss() {
        //     var myHeaders = new Headers();
        //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        //     var urlencoded = new URLSearchParams();
        //     urlencoded.append("center", id);
        //     urlencoded.append("track", tracki);
        //     var requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: urlencoded,
        //         redirect: 'follow'
        //     };
        //     fetch(`${process.env.REACT_APP_BASE_URL}/api/getalllaunched`, requestOptions)
        //         .then(response => response.json())
        //         .then(result => setSearchResult(result))
        //         .catch(error => console.log('error', error));
        // }
        function fetchDliSum() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("center", id);
            urlencoded.append("track", tracki);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
            fetch(`${process.env.REACT_APP_BASE_URL}/api/dlia1summary`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setDliSum({
                        'three_months': result.three_months,
                        'four_months': result.four_months,
                        'five_months': result.five_months,
                        'six_months': result.six_months,
                        'seven_months': result.seven_months,
                        'eight_months': result.eight_months,
                        'total_faculty': result.total_faculty,
                        'faculty_phd': result.faculty_phd,
                        'faculty_regional': result.faculty_regional,
                        'faculty_international': result.faculty_international,
                        'faculty_industrial': result.faculty_industrial,
                        'remote_learning': result.remote_learning,
                    })
                    // console.log(result)
                })
                .catch(error => console.log('error', error));
        }
        fetchDliSum();
    }, [tracki]);
    return (
        <div className="row py-2">
            <div className='d-flex overflow-scroll'>
                {tracki == 1 &&
                    <>
                        <div className=" textSum px-2 py-1 border border-1 rounded-2">
                            <p className='texth'> Number of courses launched within 3 months from effectiveness </p>
                            <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                                <p className='fs-6 text-white mt-3'> {dlisum.three_months}</p>
                            </div>
                        </div>
                        <div className=" textSum px-2 py-1 border border-1 rounded-2">
                            <p className='texth'> Number of courses launched within 4 months from effectiveness </p>
                            <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                                <p className='fs-6 text-white mt-3'> {dlisum.four_months}</p>
                            </div>
                        </div>
                        <div className=" textSum px-2 py-1 border border-1 rounded-2">
                            <p className='texth'> Number of courses launched within 5 months from effectiveness </p>
                            <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                                <p className='fs-6 text-white mt-3'> {dlisum.five_months}</p>
                            </div>
                        </div>
                        <div className=" textSum px-2 py-1 border border-1 rounded-2">
                            <p className='texth'> Number of courses launched within 6 months from effectiveness </p>
                            <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                                <p className='fs-6 text-white mt-3'> {dlisum.six_months}</p>
                            </div>
                        </div>
                        <div className=" textSum px-2 py-1 border border-1 rounded-2">
                            <p className='texth'> Number of courses launched within 7 months from effectiveness </p>
                            <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                                <p className='fs-6 text-white mt-3'> {dlisum.seven_months}</p>
                            </div>
                        </div>
                        <div className=" textSum px-2 py-1 border border-1 rounded-2">
                            <p className='texth'> Number of courses launched within 8 months from effectiveness </p>
                            <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                                <p className='fs-6 text-white mt-3'> {dlisum.eight_months}</p>
                            </div>
                        </div>
                    </>
                }
                <div className=" textSum px-2 py-1 border border-1 rounded-2 ">
                    <p className='texth'> Total number of faculty delivering the courses</p>
                    <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                        <p className='fs-6 text-white mt-3'> {dlisum.total_faculty}</p>
                    </div>
                </div>
                <div className=" textSum px-2 py-1 border border-1 rounded-2">
                    <p className='texth'> Total number of Faculty with PhD degree</p>
                    <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                        <p className='fs-6 text-white mt-3'> {dlisum.faculty_phd}</p>
                    </div>
                </div>
                <div className=" textSum px-2 py-1 border border-1 rounded-2">
                    <p className='texth'> Total number of faculty through regional academic partnership</p>
                    <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                        <p className='fs-6 text-white mt-3'> {dlisum.faculty_regional}</p>
                    </div>
                </div>
                <div className=" textSum px-2 py-1 border border-1 rounded-2">
                    <p className='texth'> Total number of faculty through international academic partnership</p>
                    <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                        <p className='fs-6 text-white mt-3'> {dlisum.faculty_international}</p>
                    </div>
                </div>
                <div className=" textSum px-2 py-1 border border-1 rounded-2">
                    <p className='texth'> Total number of faculty through Industrial partnership</p>
                    <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                        <p className='fs-6 text-white mt-3'> {dlisum.faculty_industrial}</p>
                    </div>
                </div>
                <div className=" textSum px-2 py-1 border border-1 rounded-2">
                    <p className='texth'> Number of courses available for remote learning</p>
                    <div className='d-flex bg-success rounded-3  align-items-center justify-content-center'>
                        <p className='fs-6 text-white mt-3'> {dlisum.remote_learning}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
