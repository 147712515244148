import React from 'react';
import { useRouteError } from "react-router-dom";

import { StyledLogin } from './Styles/StyledLogin.styles'
import { StyledSliderSide } from './Styles/SliderSide.style'
import headerLogo from '../images/headerLogo.png'
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";


import OTPForm from './OtpForm';

const OTP = () => {
    return (

        <StyledLogin>

            <div className='LoginSide'>
                <div className='loginNav'>
                    <div><img className='headerLogo' src={headerLogo} alt='headerLogo'></img></div>
                    <div className='margin-l-2 c-lg'>SPESSE</div>
                    <div className='margin-l-2 c-lb'>Platform</div>
                </div>
                <OTPForm />
            </div>
            <div className='SliderSide'>
                <StyledSliderSide>
                    <CCarousel className="" interval={1000}>
                        <CCarouselItem>
                            <img height={200} src={'https://adminapi.blissful-rosalind.154-68-232-27.plesk.page/images/report.png'} className="img-fluid" alt="World Bank Logo" />
                        </CCarouselItem>
                        <CCarouselItem>
                            <img height={200} src={'https://adminapi.blissful-rosalind.154-68-232-27.plesk.page/images/report2.png'} className="img-fluid" alt="NUC logo" />
                        </CCarouselItem>
                        <CCarouselItem>
                            <img height={200} src={'https://adminapi.blissful-rosalind.154-68-232-27.plesk.page/images/laptop.png'} className="img-fluid" alt="SPESSE logo" />
                        </CCarouselItem>
                    </CCarousel>
                </StyledSliderSide>
            </div>

        </StyledLogin>

    );
}

export default OTP;