import React, { useEffect, useState } from "react";
import Nav from './Nav';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteError
} from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaPlus, FaHome, FaEye, FaTrash, FaEdit, FaComments, FaShoppingBasket } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import axios from 'axios'

import Swal from "sweetalert2";

import AddCenter from './AddCenter'

const GetAllCenters = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [recentProgrammeLunched, SetRecentProgrammeLunched] = useState(null)

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
  
    const handleClose = () => setShow(false);

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = "https://stockmgt.gapaautoparts.com/api/admin/getUsers";

            try {
                const { data: response } = await axios.get(url);
                SetRecentProgrammeLunched(response.result);
                console.log(response)


            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    const getName = (event) => {
        setName(event.target.value);
    }
    const getPassword = (event) => {
        setPassword(event.target.value);
    }
    const getNumber = (event) => {
        setPhone(event.target.value);
    }
    const getEmail = (event) => {
        setEmail(event.target.value);
    }
   

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            // set configurations
            const configuration = {
                method: "post",
                url: "https://stockmgt.gapaautoparts.com/api/admin/AddAdminUser",
                data: {
                    name: name,
                    email: email,
                    phone: phone,
                    password: password,
                   
                },
            };



            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'Center Added Successfully!',
                            'success'
                        )

                        window.location.reload();

                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })


        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }


    const handleDelete = (index,e, id) => {
        SetRecentProgrammeLunched(recentProgrammeLunched.filter((v, i) => i !== index));
        try {

            // set configurations
            const configuration = {
                method: "post",
                url: `https://stockmgt.gapaautoparts.com/api/DeleteUser/${id}`,
               
            };



            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'User Deleted Successfully!',
                            'success'
                        )

                      
                        
                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })


        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }

    return (
        <StyledPageTitle>
            <div className="card-header border-bottom m-5" id="row2-card-header">
                <div className="top-sales-header">
                    <h1 className="title">Manage Users</h1>
                    <div>

                        <button className="btn btn-success btnAdd" onClick={handleShow}>
                            <FaPlus />Add New User
                        </button>
                    </div>
                </div>
            </div>
            <div className="table">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No.</th>
                           
                            <th></th>

                        </tr>
                        {
                            recentProgrammeLunched?.length > 0 && (
                                recentProgrammeLunched.map((row, key) => (
                                    <>
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.email}</td>
                                            <td>{row.phone}</td>
                                          
                                            <th></th>
                                            <td>

                                                <Link
                                                    to={`/view-permision/${row.id}`}
                                                    className="btn btn-success m-2"
                                                    title="Edit Record"
                                                >
                                                    <FaEye /> VIEW PARMISSION
                                                </Link>

                                                <Link
                                                    to={`/edit-user/${row.id}`}
                                                    className="btn btn-info m-2"
                                                    title="Edit Record"
                                                >
                                                    <FaEdit /> EDIT 
                                                </Link>


                                               
                                                <button className="btn btn-danger m-2" onClick={e => handleDelete(key,e,row.id)}>
                                                    <FaTrash />Delete
                                                </button>

                                            </td>
                                        </tr>
                                    </>
                                ))
                            )
                        }


                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>

            <>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text"
                                    onChange={getName}
                                    value={name}
                                />
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number"
                                    onChange={getNumber}
                                    value={phone}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                    onChange={getEmail}
                                    value={email}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password"
                                    onChange={getPassword}
                                    value={password}
                                />
                            </Form.Group>
                           
                            
                            <button type="submit" class="btn btn-success" >
                            Add New
                            </button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>

        </StyledPageTitle>
    );
}

export default GetAllCenters;


