import styled from "styled-components";

export const StyledSliderSide = styled.div`
    box-sizing: border-box;
    width:100%;

    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width:50%;
        margin-top: 12rem;
    }
    
`