import { useState } from "react";

const TopNav = () => {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('admin')))
    return (
        <>
            <div className="logo col-9 d-flex align-items-center">
                <img src="https://adminapi.blissful-rosalind.154-68-232-27.plesk.page/images/logo.png" alt="" />
                <h3>SPESSE DASHBOARD</h3>
            </div>
            <div className='d-flex'>
                <p className="fw-bold fs-6">{dets.name} </p>
                <p>you are Logged in as (IVA STAFF)</p>
            </div>
        </>
    );
}

export default TopNav;
