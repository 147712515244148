import React, { useEffect, useState } from "react";
import axios from 'axios'
import Nav from '../Components/Nav'
import PageTitle from '../Components/PageTitle'
import { StyledMainBody } from '../Components/Styles/MainBody.styles';
import SideNav from '../Components/SideNav';

import Permission from '../Components/Permission';

const ViewPamission = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

        let otp = localStorage.getItem("otp");
        let email = localStorage.getItem("email");
        let token = localStorage.getItem("token");

        if(token == null && email == null && otp ==null){
            window.location.href = "/";
        }

    return (
        <>
            <SideNav />
            <StyledMainBody>
                <Nav />
                <Permission />
            </StyledMainBody>
        </>

    );
}

export default ViewPamission;
