import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { Navigate, useNavigate, useParams, Link } from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaUsers, FaPlus, FaHome, FaSignOutAlt, FaCog, FaFilePrescription, FaComments, FaShoppingBasket } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { StyledCards } from './Styles/cards';
import axios from 'axios'

import { StyledPoints } from "./Styles/Points.styles";
import { StyledPointsTiles } from "./Styles/PointsTiles.styles";

import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";

import { CircleIndicator } from 'react-indicators';

import AddCenter from './AddCenter'

const Centers = () => {
    var CircleIndicator = require('react-indicators').CircleIndicator;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
   
    const [process, setProcess] = useState("");


    const handleClose = () => setShow(false);
    const { id } = useParams();

   

    const navigate = useNavigate();
    //get the last lunch programme course by the center

    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = "https://stockmgt.gapaautoparts.com/api/admin/";

            try {
                const { data: response } = await axios.get(url + `getUsersById/${id}`);
               
                console.log(response.result.name);
                setName(response.result.name);
                setEmail(response.result.email);
                setPhone(response.result.phone);
           
                

            } catch (error) {
                console.error("Something went wrong");
            }
            setLoading(false);
        }

        fetchData();
    }, []);



    const getName = (event) => {
        setName(event.target.value);
    }
   
    const getNumber = (event) => {
        setPhone(event.target.value);
    }
    const getEmail = (event) => {
        setEmail(event.target.value);
    }
   

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcess('Saving Changes, Please wait...');
        try {

            // set configurations
            const configuration = {
                method: "post",
                url: `https://stockmgt.gapaautoparts.com/api/admin/EditUser/${id}`,
                
                data: {
                    name: name,
                    email: email,
                    phone: phone,
                },
            };



            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'Programme Edited Successfully!',
                            'success'
                        )
                        setProcess("");
                        navigate("/users");
                      
                        
                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: "Sorry Something went wrong, Please try again",

                        })

                        setProcess('');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: "Sorry Something went wrong, Please try again",
                            
                        })

                        setProcess('');
                    }

                })
                .catch((error) => { 
                    console.log(error); 
                    setProcess('');
                })


        } catch (error) {
            console.log(error);
            // setError(error);
        }

       
    }


    return (
        <>
            <StyledPointsTiles>
                <div className="top-sales-header">
                
                </div>
            </StyledPointsTiles >
            <StyledCards>
                
                <div className='EditCard'>
                    
                   <div className="formgroup">
                   <form onSubmit={handleSubmit}>

                   <div className="error"> {process}</div>
                    
                    <h1 className="EditTitle">Edit User</h1>
                    
                                <div className="mb-2" >
                                <b>Please fill all the require fields</b>
                                    <div className="label">Name</div>
                                   
                                    <input type="text"
                                        onChange={getName}
                                        value={name}
                                        className="textbox"
                                    />
                                </div>
                                <div className="mb-2" controlId="formBasicEmail">
                                    <div className="label">Email</div>
                                    <input type="text"
                                        onChange={getEmail}
                                        value={email} 
                                        className="textbox"
                                        />
                                </div>


                                <div className="mb-2" controlId="formBasicEmail">
                                    <div className="label">Phone Number</div>
                                    <input type="number"
                                         onChange={getNumber}
                                         value={phone}
                                        className="textbox"
                                    />
                                </div>
                               
                               <div className="EditButton">
                               <button type="submit" class="btn btn-success" >
                                    Edit User 
                                </button>

                                <Link to="/users" class="btn btn-success" >
                                    Close
                                </Link>
                               </div>
                        </form>
                   </div>
                </div>
            </StyledCards>
            

        </>

    );
}

export default Centers;


