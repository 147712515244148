import { Eject } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv';
import { Link, useParams } from 'react-router-dom';
import SideNavIva from '../Components/IvaDashboards/Navigations/sideNav';

import TopNav from '../Components/IvaDashboards/Navigations/topNav'
import TopPills from '../Components/IvaDashboards/Navigations/topPills';
import DLiSumReports from './DLiSumReports';

function CentersInfo() {
    const { id } = useParams();
    const [notify, setNotify] = useState(' ');
    const [tracki, Settraki] = useState('1');
    const [offeringS, setOfferingS] = useState('1');
    // console.log(tracki)
    const [searchinfo, setSearchInfo] = useState({
        tracks: '',
        schema: '',
    })
    const [searchResult, setSearchResult] = useState('');
    const [tracks, setTracks] = useState('');
    const [schema, setSchema] = useState('');
    const [courses, setCourses] = useState('');

    // console.log(centerDet)

    useEffect(() => {
        function tracksss() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("center", id);
            urlencoded.append("track", tracki);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
            fetch(`${process.env.REACT_APP_BASE_URL}/api/getalllaunched`, requestOptions)
                .then(response => response.json())
                .then(result => setSearchResult(result))
                .catch(error => console.log('error', error));
        }

        tracksss()

    }, [tracki]);
    useEffect(() => {
        const fetchData = () => {
            const allSchematicAreas = `${process.env.REACT_APP_BASE_URL}/api/schematic_areas`;
            const allTracks = `${process.env.REACT_APP_BASE_URL}/api/tracks`;

            // const allCourses = `${process.env.REACT_APP_BASE_URL}/api/courses`;
            const getallSchema = axios.get(allSchematicAreas);
            const getallTracks = axios.get(allTracks);


            // const getallCourse = axios.get(allCourses);
            axios.all([getallSchema, getallTracks,]).then(
                axios.spread((...allData) => {
                    const allScema = allData[0].data;
                    const alltracks = allData[1].data;
                    // const centerDetails = allData[2].data.data;

                    // const allcourses = allData[2].data;
                    setTracks(alltracks)
                    setSchema(allScema)
                    // setCourses(allcourses)

                })
            )
        }
        fetchData()
    }, [])

    console.log(tracki);
    function Query(e) {
        const schema = e.target.value
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("schema_id", schema);
        urlencoded.append("track_id", tracki);
        urlencoded.append("center", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getallinschema`, requestOptions)
            .then(response => response.json())
            .then(result => setSearchResult(result))
            .catch(error => console.log('error', error));
    }

    function all() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getalllaunched`, requestOptions)
            .then(response => response.json())
            .then(result => setSearchResult(result))
            .catch(error => console.log('error', error));
    }
    return (
        <>
            <div className='container'>
                {/* <TopPills />
                <div className='row g-3 text-dark justify-content-between mb-3'>
                    <a onClick={() => { Settraki('1') }} className={tracki == '1' ? 'selected col-1  d-flex justify-content-center ' : '  col-1  d-flex justify-content-center '}>
                        <p className='fw-bold'> Track A</p>
                    </a>

                    <a onClick={() => { Settraki('2') }} className={tracki == '2' ? 'selected col-1  d-flex justify-content-center ' : '  col-1  d-flex justify-content-center '}>
                        <p className='fw-bold'> Track B</p>

                    </a>
                    <a onClick={() => { Settraki('3') }} className={tracki == '3' ? 'selected col-1  d-flex justify-content-center ' : '  col-1  d-flex justify-content-center '}>
                        <p className='fw-bold'> Track C</p>

                    </a>
                    <a onClick={() => { Settraki('4') }} c className={tracki == '4' ? 'selected col-1  d-flex justify-content-center ' : '  col-1  d-flex justify-content-center '}>
                        <p className='fw-bold'> Track D</p>

                    </a>
                    <a onClick={() => { Settraki('5') }} className={tracki == '5' ? 'selected col-1  d-flex justify-content-center ' : '  col-1  d-flex justify-content-center '}>
                        <p className='fw-bold'> Track E</p>

                    </a>
                </div> */}


                <DLiSumReports tracki={tracki} id={id} />


                <div className='row mb-3'>
                    <div className='col-6'>
                        <div>
                            <label>Select Tracks</label>
                            <div className='input-group'>
                                <select onChange={(e) => { Settraki(e.target.value) }} className='form-control'>
                                    <option value="none">--select track ---</option>
                                    <option value="1">Track A</option>
                                    <option value="2">Track B</option>
                                    <option value="3">Track C</option>
                                    <option value="4">Track D</option>
                                    <option value="5">Track E</option>
                                </select>
                                {/* <button onClick={all} className='btn-primary btn'>All</button> */}
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div>
                            <label>Select Thematic Area</label>
                            <div className='input-group'>
                                <select className='form-control' onChange={Query}>
                                    <option value="none">--select thematic areas ---</option>
                                    {Array.isArray(schema) && schema.map(schema => {
                                        return (
                                            <option value={schema.id} key={schema.id}>{schema.title}</option>
                                        )
                                    })
                                    }
                                </select>
                                <button onClick={all} className='btn-primary btn'>All</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='card p-4'>
                    <div className='text-end'>
                        {Array.isArray(searchResult) && <CSVLink data={searchResult}>
                            <button className='btn btn-success'><Eject /> Export Data</button></CSVLink>}
                    </div>
                    <div className='text-dark overflow-scroll'>
                        <table className='table table-sm table-hover'>
                            <thead>
                                <th>Course No</th>
                                <th>Certificate Title</th>
                                <th>Course Title</th>
                                <th>Date Announced </th>
                                <th>Offerings Per year</th>
                                <th>actions</th>
                            </thead>
                            <tbody>
                                {Array.isArray(searchResult) && searchResult.map(Result => {
                                    return (
                                        <tr key={searchResult.indexOf(Result)}>
                                            <td>
                                                {searchResult.indexOf(Result) + 1}
                                            </td>
                                            <td>
                                                {
                                                    Result.cert_title == null ? 'Short Course' : Result.cert_title
                                                }
                                            </td>
                                            <td>
                                                {Result.course_title}
                                            </td>

                                            <td>
                                                {Result.announcement_date}
                                            </td>
                                            <td>{Result.offerings}</td>
                                            <td>
                                                <div className='btn-group'>
                                                    <a href={`/coursesview/${Result.id}/${id}`} className='btn btn-primary btn-sm'>
                                                        view trainees
                                                    </a>
                                                    <a href={`/facultyview/${Result.id}/${id}`} className='btn btn-danger btn-sm'>
                                                        view faculties</a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}




export default CentersInfo
