import styled from "styled-components";

export const StyledPointsTiles = styled.nav`
    display: flex;
    justify-content: space-between;
    height: auto;
    padding: 0 50px;
    align-items: center;
    flex-wrap: wrap;
    margin-top:120px;
`