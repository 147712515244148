import React, { useEffect, useState } from "react";
import axios from 'axios'
import SideNav from "../Components/SideNav";
import Nav from "../Components/Nav";
import GetAllDashboards from "./components/getAllDashboards";
import Swal from "sweetalert2";
// import logo from ("./images/women.jpg");

let path = window.location.pathname;
let schema = path.includes('women_affairs') ? "2" : path.includes('bpp') ? "1" : "3";
let organizer = path.includes('women_affairs') ? "women_affairs" : path.includes('bpp') ? "bpp" : "environment";
let dashboard = path.includes('women_affairs') ? "Women Affairs" : path.includes('bpp') ? "BPP" : "";


let url_image = path.includes('women_affairs') ? "https://host.spesse.edu.ng/images/women.jpg" : path.includes('bpp') ? "https://www.bpp.gov.ng/wp-content/uploads/2019/02/BPP-LOGO-2-1.png" : "https://environment.gov.ng/wp-content/uploads/2022/08/logo-2.png";

const WomenAffairsDash = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('admin')));
    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");
    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    }
    const [center, setCenter] = useState('');
    const [centers, setCenters] = useState('');
    // const [schema, setSchema] = useState(schema);
    const [verification, setVerification] = useState('');
    const [uploaded, setUploaded] = useState('');
    const [updated, setUpdated] = useState('');

    const [availableDates, setAvailableDates] = useState('')
    const [pdoNames, setPdoName] = useState('')

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/all_upload_base/${organizer}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setUploaded(result.data)
                localStorage.setItem('pdoArray', JSON.stringify(result))
            })
            .catch(error => console.log('error', error));
    }, [updated]);
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/allpdo`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setPdoName(result)
                localStorage.setItem('pdoArray', JSON.stringify(result))
            })
            .catch(error => console.log('error', error));
    }, []);
    useEffect(() => {
        let token = localStorage.getItem("token");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/allcenters`, requestOptions)
            .then(response => response.json())
            .then(result => setCenters(result))
            .catch(error => console.log('error', error));
    }, []);
    // $table->string('organizer');
    //         $table->string('pdo_date_id');
    //         $table->integer('pdo_schema_id');
    //         $table->string('undergraduate');
    //         $table->string('post_graduate');
    //         $table->string('diploma');
    //         $table->string('msc');

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/dates`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setAvailableDates(result);
            })
            .catch(error => console.log('error', error));

    }, [])


    const [uploadingData, setUploadingData] = useState({
        organizer: organizer,
        pdo_date_id: '',
        pdo_indicator_id: '',
        pdo_schema_id: schema,
        undergraduate: '',
        post_graduate: '',
        diploma: '',
        msc: '',
        center_id: '',
    })
    // console.log(uploadingData)
    async function handleSave(e) {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("organizer", uploadingData.organizer)
        urlencoded.append("pdo_date_id", uploadingData.pdo_date_id)
        urlencoded.append("pdo_indicator_id", uploadingData.pdo_indicator_id)
        urlencoded.append("pdo_schema_id", uploadingData.pdo_schema_id)
        urlencoded.append("undergraduate", uploadingData.undergraduate)
        urlencoded.append("post_graduate", uploadingData.post_graduate)
        urlencoded.append("diploma", uploadingData.diploma)
        urlencoded.append("msc", uploadingData.msc)
        urlencoded.append("center_id", uploadingData.center_id)


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/save_upload_base`, requestOptions)
        const data = await response.json()
        if (response.status == 200) {
            Swal.fire(
                'Success!',
                'Verified Successfully!',
                'success'
            )
            setUpdated(Math.random())
        }

    }

    function deleteItem(item) {

        console.log(item);

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/deletepud/${item}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                Swal.fire(
                    'Success!',
                    'Deleted Successfully!',
                    'success'
                )
                setUpdated(Math.random())
            })
            .catch(error => console.log('error', error));
    }
    // console.log(uploadingData.pdo_indicator_id);
    return (
        <>
            <div className="d-flex align-items-center shadow justify-content-between p-3">
                <div className="d-flex align-items-center ">
                    <img className="pe-3" height={100} src={url_image} alt="Women affairs Logo" />
                    <div>
                        <h2>
                            {dashboard} Dashboard
                        </h2>
                    </div>
                </div>
                <div>
                    <p className="mb-3"> {user.name} logged in</p>

                    <button className="btn btn-danger" onClick={() => {
                        window.localStorage.clear();
                        window.location.href = '/';
                    }}>Log Out</button>
                </div>

            </div>

            <div className="row pt-5 justify-content-center">
                <div className="col-4">

                    <form onSubmit={handleSave}>
                        <div className="p-3">
                            <h2 className="text-center">Verification Approval Form</h2>
                            <hr />

                            <div className="row justify-content-between ">
                                <div className="col-12 mb-3">
                                    <label>Verification Date</label>
                                    <select type="text" onChange={(e) => {
                                        setUploadingData({ ...uploadingData, pdo_date_id: e.target.value })
                                    }} className="form-control">
                                        <option value="none">--Select the Verification Date</option>
                                        {Array.isArray(availableDates) && availableDates.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index + 1}>{item.start_date}--{item.status}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-6 mb-3">
                                    <label>Pdo Indicator to Fill</label>
                                    <select type="text" onChange={(e) => {
                                        setUploadingData({ ...uploadingData, pdo_indicator_id: e.target.value })
                                    }} className="form-control">
                                        <option value="none">--Select the PDO INDICATOR</option>
                                        {Array.isArray(pdoNames) && pdoNames[0].categories[0].indicators.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index + 1}>{item.title}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-6 mb-3">
                                    <label>SPESSCE Centers</label>
                                    <select type="text" onChange={(e) => {
                                        setUploadingData({ ...uploadingData, center_id: e.target.value })
                                    }} className="form-control">
                                        <option value="none">--Select the Center</option>
                                        {Array.isArray(centers) && centers.map((item, index) => {
                                            return (
                                                <option value={item.uniq_id} key={index + 1}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>


                                {
                                    uploadingData.pdo_indicator_id == 1 ?
                                        <div className="row">
                                            <div className="col-6 mb-3">
                                                <label>Number</label>
                                                <input type="number" onChange={(e) => {
                                                    setUploadingData({ ...uploadingData, undergraduate: e.target.value })
                                                }} min={0} className="form-control" placeholder="enter number " />

                                            </div>

                                        </div> :
                                        uploadingData.pdo_indicator_id == 2 ?
                                            <div className="row">
                                                <div className="col-6 mb-3">
                                                    <label>Public</label>
                                                    <input type="number" onChange={(e) => {
                                                        setUploadingData({ ...uploadingData, undergraduate: e.target.value })
                                                    }} min={0} className="form-control" placeholder="enter number of public staff verified" />
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <label>Private</label>
                                                    <input type="number" onChange={(e) => {
                                                        setUploadingData({ ...uploadingData, post_graduate: e.target.value })
                                                    }} min={0} className="form-control" placeholder="enter number of private staff" />
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <label>New Staff</label>
                                                    <input type="number" onChange={(e) => {
                                                        setUploadingData({ ...uploadingData, diploma: e.target.value })
                                                    }} min={0} className="form-control" placeholder="enter number of new staff " />
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <label>Existing Staff(Promoted)</label>
                                                    <input type="number" onChange={(e) => {
                                                        setUploadingData({ ...uploadingData, msc: e.target.value })
                                                    }} min={0} className="form-control" placeholder="enter number of existing staff" />
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <label>Female Staff</label>
                                                    <input type="number" onChange={(e) => {
                                                        setUploadingData({ ...uploadingData, msc: e.target.value })
                                                    }} min={0} className="form-control" placeholder="enter number of female staff" />
                                                </div>
                                            </div>
                                            :
                                            uploadingData.pdo_indicator_id == 3 ?
                                                <div className="row">
                                                    <div className="col-6 mb-3">
                                                        <label>Undergraduate</label>
                                                        <input type="number" onChange={(e) => {
                                                            setUploadingData({ ...uploadingData, undergraduate: e.target.value })
                                                        }} min={0} className="form-control" placeholder="enter number of undergraduate verified" />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label>Postgraduate</label>
                                                        <input type="number" onChange={(e) => {
                                                            setUploadingData({ ...uploadingData, post_graduate: e.target.value })
                                                        }} min={0} className="form-control" placeholder="enter number of post graduate verified" />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label>Diploma</label>
                                                        <input type="number" onChange={(e) => {
                                                            setUploadingData({ ...uploadingData, diploma: e.target.value })
                                                        }} min={0} className="form-control" placeholder="enter number of diploma verified" />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label>Masters(MSC)</label>
                                                        <input type="number" onChange={(e) => {
                                                            setUploadingData({ ...uploadingData, msc: e.target.value })
                                                        }} min={0} className="form-control" placeholder="enter number of msc verified" />
                                                    </div>
                                                </div>
                                                :
                                                <div className="mb-3">
                                                    <label>Percentage</label>
                                                    <input type="number" onChange={(e) => {
                                                        setUploadingData({ ...uploadingData, undergraduate: e.target.value })
                                                    }} min={0} className="form-control" placeholder="enter percentage" />
                                                </div>
                                }

                                <div className="mb-3">
                                    <label>Custom</label>
                                    <input type="number" onChange={(e) => {
                                        setUploadingData({ ...uploadingData, undergraduate: e.target.value })
                                    }} min={0} className="form-control" placeholder="enter custom" />
                                </div>
                                <button className="btn btn-primary col-5 m-auto">Verify</button>

                            </div>
                        </div>

                    </form>
                </div>
                <div className="col-7">

                    <div className="p-3 table-responsive">
                        <h2>Report Verified</h2>
                        <table className="table table-sm table-hover">
                            <thead>
                                <th>#</th>
                                <th>Center Id and Name</th>
                                <th>Verification Date</th>
                                <th>Undergraduate</th>
                                <th>Postgraduate</th>
                                <th>Diploma</th>
                                <th>Msc</th>
                            </thead>

                            {Array.isArray(uploaded) && uploaded.map((item, index) => {
                                return (
                                    <tr key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td>{item.centers_name}-{item.center_id}</td>
                                        <td>{item.verify_start_date}</td>
                                        <td>{item.undergraduate}</td>
                                        <td>{item.post_graduate}</td>
                                        <td>{item.diploma}</td>
                                        <td>{item.msc}</td>
                                        <td><button onClick={() => { deleteItem(item.id_up) }} className="btn btn-danger btn-sm">Delete</button></td>
                                    </tr>
                                )
                            })}

                        </table>

                    </div>
                </div>
            </div>

            {/* <SideNav />
            <StyledMainBody>
                
            </StyledMainBody> */}
        </>

    );
}

export default WomenAffairsDash;
