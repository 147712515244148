import styled from "styled-components";

export const StyledNav = styled.nav`
    display: flex;
    align-item:center;
    padding: 0px;
    margin:0px;
    align-items: center;
    height: 15vh;    
    color:#fff;
    position:fixed;
    background-color: #F5F6FA;
    color:green;
    z-index:1000;
    width:93%;
    box-sizing: border-box;
   
    border-radius: 3px;
    box-shadow: 0px 2px 0px green;
    .logo{
        display:flex;
        justify-content: betweem;
    }
    h1{
        font-weight: 400;
        font-size: 30px;
        color:#171717;
        padding-top:15px;
    }

    h3 {
        font-weight: 400;
        font-size: 23px;
       
        color:#171717;
    }

    label {
        margin-left: auto;
        margin-right: 10px;
        opacity: 0.8;
        font-weight: 400;
        font-size: 15px;
        color:#171717;
    }

    p {
        margin-right: 10px;
        font-size: 16px;
    }

    img {
        margin-left: 0px;
        width: 110px;
        height: 90px;
        color:#fff;
    }

    .userName {
        
    }

    .userSurname {
        border-right: 1.5px solid rgba(0, 0, 0, 0.5); 
        padding-right: 10px;
        color:green;
        margin-top:25px;
    }

    .userName{
        color:green;
        font-weight: 700;
        margin-top:25px;
        margin-left:30%;

    }

    .loginAs{
        margin-top:25px;
    }

`