import { Eject } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import SideNavIva from '../Components/IvaDashboards/Navigations/sideNav';


import TopNav from '../Components/IvaDashboards/Navigations/topNav'
import TopPills from '../Components/IvaDashboards/Navigations/topPills';

function IvaDashboard() {
    const [notify, setNotify] = useState(' ');
    const [searchinfo, setSearchInfo] = useState({
        tracks: '',
        schema: '',
    })
    const [searchResult, setSearchResult] = useState('');

    // console.log(searchResult);
    const [tracks, setTracks] = useState('');
    const [schema, setSchema] = useState('');
    const [courses, setCourses] = useState('');
    const [centers, setCenters] = useState('');

    console.log(searchResult)
    useEffect(() => {
        const fetchData = () => {
            const allCenters = `${process.env.REACT_APP_BASE_URL}/api/allcenters`;
            const getallCenters = axios.get(allCenters);
            axios.all([getallCenters]).then(
                axios.spread((...allData) => {
                    const allcenters = allData[0].data;
                    setCenters(allcenters)


                })
            )
        }
        fetchData()
    }, [])
    function Query(e) {
        const id = e.target.value
        window.location.href = `/centersview/${id}`
        //     console.log(id)
        //     var requestOptions = {
        //         method: 'GET',
        //         redirect: 'follow'
        //     };

        //     fetch(`${process.env.REACT_APP_BASE_URL}/api/getcoursesinprog/?schema_id=${id}&track_id=${1}`, requestOptions)
        //         .then(response => response.json())
        //         .then(result => setSearchResult(result))
        //         .catch(error => console.log('error', error));
        // }
    }
    function all() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getalllaunched`, requestOptions)
            .then(response => response.json())
            .then(result => setSearchResult(result))
            .catch(error => console.log('error', error));
    }
    function redirect(id) {
        window.location.href = `/centersview/${id}`
    }
    return (
        <>

            <div className='mainbody'>
                <div className='row justify-content-center'>
                    <div className='col-1 navbarIVa text-center py-5 shadow '>
                        <SideNavIva />
                    </div>
                    <div className='col-11'>
                        <div className='d-flex navheader mb-5 justify-content-between align-items-center p-3 shadow'>
                            <TopNav />
                        </div>
                        <div className='container'>
                            <div className='row mb-3'>
                                <div className='col-6'>
                                    <div>
                                        <label>Kindly Select Center</label>
                                        <div className='input-group'>
                                            <select className='form-control' onChange={Query}>
                                                <option value="none">--Select Centers  ---</option>
                                                {Array.isArray(centers) && centers.map(center => {
                                                    return (
                                                        <option value={center.uniq_id} >
                                                            {center.name}
                                                        </option>

                                                    )
                                                })
                                                }
                                            </select>
                                            <button onClick={all} className='btn-primary btn'>All</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='p-4'>
                                <div className='text-dark row g-3 justify-content-around'>
                                    {Array.isArray(centers) && centers.map(center => {
                                        return (
                                            <>
                                                <div onClick={() => {
                                                    redirect(center.uniq_id)
                                                }} className=' col-5 border-1 text-start centerdetail'>
                                                    <div className='fs-5 mb-3 fw-bold'>
                                                        <span className='fs-4'>Center Id</span>: {center.uniq_id}
                                                    </div>
                                                    <div className='fs-5 mb-3'>
                                                        <span className='fs-4 fw-bold'>Center Name</span>:   {center.name}
                                                    </div>
                                                    <div className='mb-3'>
                                                        <span className='fs-4 fw-bold'>Address</span>: {center.address}
                                                    </div>
                                                    <div className='mb-3'>
                                                        <span className='fs-4 fw-bold'>Email Address</span>:  {center.email}</div>
                                                    <div className='mb-3'>
                                                        <span className='fs-4 fw-bold'>Institution type</span>: {center.type}
                                                    </div>
                                                    <div className='mb-3'>
                                                        <span className='fs-4 fw-bold'>Geopolitical Zone </span>:   {center.zone}</div>
                                                </div></>
                                        )
                                    })
                                    }
                                    {Array.isArray(searchResult) && searchResult.map(Result => {
                                        return (
                                            <tr key={Result.id}>
                                                <td>
                                                    {searchResult.indexOf(Result) + 1}
                                                </td>
                                                <td>
                                                    {Result.course_title}
                                                </td>

                                                <td>
                                                    {Result.announcement_date}
                                                </td>
                                                <td>{Result.offerings}</td>
                                                <td>
                                                    <div className='btn-group'>
                                                        <a href={'/courseview/' + Result.launched_id} className='btn btn-primary btn-sm'>
                                                            view trainees
                                                        </a>
                                                        <a className='btn btn-danger btn-sm'>
                                                            view faculties</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}




export default IvaDashboard
