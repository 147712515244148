import React, { useEffect, useState } from "react";
import Nav from './Nav';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteError
} from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaPlus, FaHome, FaEye, FaTrash, FaEdit, FaComments, FaShoppingBasket } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import axios from 'axios'

import Swal from "sweetalert2";

import AddCenter from './AddCenter'
import ProgressBar from "@ramonak/react-progress-bar";

import Progress from './Progress'

const GetAllCenters1 = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [recentProgrammeLunched, SetRecentProgrammeLunched] = useState(null)

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [type, setType] = useState("");

    const handleClose = () => setShow(false);




    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = "https://stockmgt.gapaautoparts.com/api/admin/GetAllCenters";

            try {
                const { data: response } = await axios.get(url);
                SetRecentProgrammeLunched(response.result);
                console.log(response)


            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    const getName = (event) => {
        setName(event.target.value);
    }
    const getCode = (event) => {
        setCode(event.target.value);
    }
    const getNumber = (event) => {
        setPhone(event.target.value);
    }
    const getEmail = (event) => {
        setEmail(event.target.value);
    }
    const getaddress = (event) => {
        setAddress(event.target.value);
    }
    const getType = (event) => {
        setType(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            // set configurations
            const configuration = {
                method: "post",
                url: "https://stockmgt.gapaautoparts.com/api/center/AddNewCenter",
                data: {
                    center_name: name,
                    center_code: code,
                    phone_number: phone,
                    email: email,
                    address: address,
                    type: type,
                    password: '123456',
                    center_otp: '12345',
                },
            };



            axios(configuration)
                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'Center Added Successfully!',
                            'success'
                        )

                        window.location.reload();

                    } else if (result.status == 400 || result.status == 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })


        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }




    return (
        <StyledPageTitle>
            <div className="card-header border-bottom m-5" id="row2-card-header">
                <div className="top-sales-header">
                    <h1 className="title">DLI Verification Report</h1>
                    <div>

                        {/* <button className="btn btn-success btnAdd" onClick={handleShow}>
                            <FaPlus />Add New Center
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="table">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th className="w-10">#</th>
                            <th className="w-20">Name</th>
                           
                          
                           
                            <th className="w-60"></th>
                            <th className="w-10"></th>

                        </tr>
                        {
                            recentProgrammeLunched?.length > 0 && (
                                recentProgrammeLunched.map((row, key) => (
                                    <>
                                        <tr key={key}>
                                            <td className="w-10">{key + 1}</td>
                                            <td className="w-20">{row.center_name} ({row.center_code})</td>
                                           
                                         
                                           
                                            <th className="w-60">
                                                <Progress  props={{ center_id: row.center_id }}/>
                                            </th>
                                            <td className="w-10">

                                                <Link
                                                    to={`/review/${row.id}`}
                                                    className="btn btn-success m-2"
                                                    title="Edit Record"
                                                >
                                                    <FaEye /> Review 
                                                </Link>

                                                

                                            </td>
                                        </tr>
                                    </>
                                ))
                            )
                        }


                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>

            <>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Center</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Center Name</Form.Label>
                                <Form.Control type="text"
                                    onChange={getName}
                                    value={name}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Center Code</Form.Label>
                                <Form.Control type="text"
                                    onChange={getCode}
                                    value={code} 
                                    />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number"
                                    onChange={getNumber}
                                    value={phone}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Center Email</Form.Label>
                                <Form.Control type="email"
                                    onChange={getEmail}
                                    value={email}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                    onChange={getaddress}
                                    value={address}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select"
                                    onChange={getType}
                                    value={type}>
                                    <option></option>
                                    <option>University</option>
                                    <option>Politechnics</option>
                                    <option>School of Education</option>
                                </Form.Control>
                            </Form.Group>
                            <button type="submit" class="btn btn-success" >
                                Save Changes
                            </button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>

        </StyledPageTitle>
    );
}

export default GetAllCenters1;


