
import React, { useEffect, useState } from 'react'

export default function SchematicRows({ schemaTitle, schema, tracks, pdoRes, centers }) {

    const [trackitem, setTrackItem] = useState(tracks)


    return (
        <>
            <tr className="themaArea">

                <td>
                    {schemaTitle}
                </td>
                <td>

                </td>
                <td >

                </td>
                {Array.isArray(centers) && centers.map(

                    (center, index) => {
                        return (
                            <td key="" className='p-2 mb-2'>
                                {/* {center.name} */}
                            </td>
                        )
                    }
                )}


            </tr>
            {Array.isArray(tracks) && tracks.map(
                (trackitem, index) => {
                    return (
                        <tr key="" className='p-2 mb-2'>

                            <td>
                                {trackitem.title}
                            </td>
                            <td className='actualborder text-center'>

                            </td>
                            <td className='text-center'>

                            </td>
                            {Array.isArray(centers) && centers.map(
                                (center, index) => {
                                    return (Array.isArray(pdoRes) ? pdoRes.map((pdoResCenter, index) => {
                                        if ((pdoResCenter.center_id == center.uniq_id)) {
                                            return (
                                                <td key={index} className='p-2 text-center fs-6 fw-bold mb-2'>
                                                    {pdoResCenter['schema'][schema.id]['tracks'][trackitem.title]}
                                                </td>
                                            )
                                        }
                                    }
                                    ) :
                                        <td key="" className='p-2 text-center fs-6 fw-bold mb-2'>
                                            0
                                        </td>
                                    )
                                }
                            )}
                        </tr>
                    )
                }
            )}
        </>
    )
}
