import React, { useEffect, useState } from "react";
import Nav from './Nav';
import { useRouteError } from "react-router-dom";
import { StyledCards } from './Styles/Cards.syles';


import axios from 'axios'

import Swal from "sweetalert2";


const Cards = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])
    const [totalCenters, SetTotalCenters] = useState(null)
    const [totalpartners, SetTotalpartners] = useState(null)
    const [totalProgramme, SetTotalProgramme] = useState(null)
    const [totalProgrammeLunched, SetTotalProgrammeLunched] = useState(null)
    // useEffect(() => {
    //     const fetchData = () => {
    //         const allSchematicAreas = `${process.env.NEXT_PUBLIC_API_BASE}/api/schematic_areas`;
    //         const allTracks = `${process.env.NEXT_PUBLIC_API_BASE}/api/tracks`;
    //         const allCourses = `${process.env.NEXT_PUBLIC_API_BASE}/api/courses`;
    //         const getallSchema = axios.get(allSchematicAreas);
    //         const getallTracks = axios.get(allTracks);
    //         const getallCourse = axios.get(allCourses);
    //         axios.all([getallSchema, getallTracks, getallCourse]).then(
    //             axios.spread((...allData) => {
    //                 const allScema = allData[0].data;
    //                 const alltracks = allData[1].data;
    //                 const allcourses = allData[2].data;
    //                 SetTotalCenters(alltracks)
    //                 SetTotalProgramme(allScema)
    //                 setTot(allcourses)

    //             })
    //         )
    //     }
    //     fetchData()
    // }, [])
    useEffect(() => {
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            setLoading(true);

            let url = `${process.env.REACT_APP_BASE_URL}/api/gettotalsummary`;

            try {
                const { data: response } = await axios.get(url);
                setData(response);

                SetTotalCenters(response.centers)
                SetTotalpartners(response.partners)
                SetTotalProgramme(response.courses)
                SetTotalProgrammeLunched(response.students)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);


    return (
        // <StyledCards>
        <div className='cardi d-flex justify-content-around align-items-center my-4'>
            <div className="cardpills d-flex justify-content-center text-white text-center p-5  ">
                <div>
                    <div className='figur display-4 fw-bold'>{totalCenters}</div>
                    <div className='titl'>Total Centers</div>
                </div>
            </div>

            <div className="cardpills   d-flex justify-content-center text-white  text-center p-5 ">
                <div>
                    <div className='figur display-4 fw-bold'>{totalpartners}</div>
                    <div className='titl'>Total Partners</div>
                </div>
            </div>

            <div className="cardpills  d-flex justify-content-center text-white  text-center p-5 ">

                <div> <div className='figur display-4 fw-bold'>{totalProgramme}</div>
                    <div className='titl'>Launched Courses</div>
                </div>
            </div>

            <div className="cardpills  d-flex justify-content-center text-white  text-center p-5 ">
                <div>
                    <div className='figur display-4 fw-bold'>{totalProgrammeLunched}</div>
                    <div className='titl'>Total Students</div>
                </div>
            </div>
        </div>
        // </StyledCards>
    );
}

export default Cards;
