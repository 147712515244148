import { Eject } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { CSVDownload, CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import SideNavIva from './IvaDashboards/Navigations/sideNav';
import TopNav from './IvaDashboards/Navigations/topNav';
import TopPills from './IvaDashboards/Navigations/topPills';
import IvaTotalCards from './IvatotalCards';

function FacultyViewsIVA() {
    const { id, center_id } = useParams();
    const [searchResult, setSearchResult] = useState('')
    const [course, setCourseData] = useState('')
    const [session, setSession] = useState('')
    const [sessionsi, setSessionsi] = useState(' ')
    const [offering, setOfferings] = useState(' ')

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        if (sessionsi != ' ') {
            urlencoded.append("session_id", sessionsi);
        }
        if (offering != ' ') {
            urlencoded.append("offering_num", offering);
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getfacultiescourse`, requestOptions)
            .then(response => response.json())
            .then(result =>
                setSearchResult(result),
                // setCourseData(result.course)

            )
            .catch(error => console.log('error', error));
    }, [sessionsi, offering])

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getallsession/${center_id}`, requestOptions)
            .then(response => response.json())
            .then(result =>
                setSession(result),
            )
            .catch(error => console.log('error', error));
    }, [])
    return (
        <>
            <IvaTotalCards />
            <div className='container'>
                {
                    Array.isArray(searchResult.data) ? <div className='my-5'>
                        <div className='mb-3'>
                            <span className='fw-bold' > Track:</span>  {searchResult.course.track_title}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Schematic Area: </span>   {searchResult.course.schematic_title}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Course Name: </span>   {searchResult.course.course_title}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' >  Mode of Delivery:</span>   face to face
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Course Start date:</span>  {searchResult.course.start_date}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Course End date:</span>  {searchResult.course.end_date}
                        </div>
                        <hr />

                        <div>
                            <div className='mb-3'>
                                {searchResult.course.track_title == 'Track A' &&
                                    <select className='form-control col-6 ' onChange={(e) => { setOfferings(e.target.value) }}>
                                        <option value="1">--select the offering--</option>
                                        <option value="1">1st Offering</option>
                                        <option value="2">2nd Offering</option>
                                        <option value="3">3rd Offering</option>
                                        <option value="4">4th Offering</option>
                                        <option value="5">5th Offering</option>
                                        <option value="6">6th Offering</option>
                                        <option value="7">7th Offering</option>
                                        <option value="8">8th Offering</option>
                                        <option value="9">9th Offering</option>
                                        <option value="10">10th Offering</option>
                                    </select>
                                }
                            </div>
                            <div className='mb-3'>
                                {searchResult.course.track_title == 'Track E' &&
                                    <select className='form-control col-6 ' onChange={(e) => { setSessionsi(e.target.value) }}>
                                        <option value="" key="">---select session---</option>
                                        {
                                            Array.isArray(session) && session.map(data => {
                                                return (
                                                    <option value={data.id} key={data.id}>{data.title}//{data.status}</option>
                                                )
                                            })
                                        }
                                    </select>

                                }
                            </div>

                        </div>
                    </div> : ''
                }


                <div className='card p-4'>
                    <div className='text-end mb-3'>
                        {Array.isArray(searchResult.data) && <CSVLink data={searchResult.data}>
                            <button className='btn btn-success'><Eject /> Export Data</button></CSVLink>}

                    </div>
                    <div className='text-dark table-responsive'>
                        <table className='table table-sm table-hover'>
                            <thead className='fw-bold'>
                                <th className='fw-bold'>Faculty No</th>
                                <th className='fw-bold'>Faculty Name</th>
                                <th className='fw-bold'>Gender </th>
                                <th className='fw-bold' >Age</th>
                                <th className='fw-bold'>Nationality</th>
                                <th className='fw-bold'>Highest Qualification</th>
                                <th className='fw-bold'>Partnership</th>
                                <th className='fw-bold'>Phone Number</th>
                                <th className='fw-bold'>Email</th>
                            </thead>
                            <tbody>
                                {Array.isArray(searchResult.data) && searchResult.data.map(Result => {
                                    return (
                                        <tr key={Result.id}>
                                            <td>
                                                {searchResult.data.indexOf(Result) + 1}
                                            </td>
                                            <td>
                                                {Result.first_name} {Result.last_name}
                                            </td>
                                            <td>
                                                {Result.gender}
                                            </td>
                                            <td>{Result.age}</td>
                                            <td>{Result.nationality}</td>
                                            <td>{Result.highest_qual}</td>
                                            <td>{Result.partnership}</td>
                                            <td>{Result.phone_number}</td>
                                            <td>
                                                {Result.email_address}
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FacultyViewsIVA
