import { CircularProgress } from "@mui/material";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function AllCert() {
    const [cert, setCert] = useState([])
    const { id } = useParams()
    // const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    // const [update, setUpdate] = useState('');
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/certificates/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setCert(result.certs.reverse()))
            .catch(error => console.log('error', error));
    }, [])

    console.log(cert)

    // function deletecert(param) {
    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    //     var requestOptions = {
    //         method: 'get',
    //         headers: myHeaders,
    //         redirect: 'follow'
    //     };

    //     fetch(`${process.env.REACT_APP_BASE_URL}/api/deletecert/${param}`, requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             Swal.fire({
    //                 title: 'Certificate Deleted Succesfully',
    //                 icon: 'success',
    //                 confirmButtonText: 'close'
    //             })
    //             setUpdate(Math.random())
    //         }
    //         )
    //         .catch(error => console.log('error', error));
    // }

    return (<>
        <div>
            <div className='d-flex align-items-center justify-content-between py-4'>
            </div>
            <div className="bg-info p-4 shadow rounded-0  table-responsive ">

                <div>
                    <h6 className="fw-bold">Total No of Created cert: {cert.length}</h6>
                </div>
                <table className="tableData table-hover table-striped table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>CERTIFICATE TITLE</th>
                            <th>TOTAL CREDITS/NO OF COURSES</th>
                            <th>DATE APPROVED</th>
                            <th>APPROVAL DOC</th>
                            <th>LAUNCH DATE</th>
                            <th>TRACK</th>

                        </tr>
                    </thead>
                    <tbody>
                        {cert === ' ' ? <p><CircularProgress /></p> :
                            cert.map(certi => {
                                return (
                                    <tr className='align-items-center '>
                                        <td>{cert.indexOf(certi) + 1}</td>
                                        <td>{certi.title}</td>
                                        <td>{certi.credits}/{certi.number_of_courses}</td>
                                        <td>{certi.dateapproved}</td>
                                        <td><a target={'_blank'} href={`${process.env.REACT_APP_BASE_URL}/approvals/${certi.approve_doc}`}>{certi.approve_doc}</a></td>

                                        <td>{certi.launchdate}</td>
                                        <td>{certi.track_title}</td>
                                        {/* {dets.permission_type != 'View' &&

                                            <td>
                                                <div className='btn-group'>
                                                    <a href={`/editcertificates/${certi.id}`} >
                                                        <button className='btn btn-sm  btn-success p-2'>
                                                            Edit
                                                        </button>
                                                    </a>

                                                    <button onClick={() => {
                                                        deletecert(certi.id)
                                                    }}
                                                        className='btn btn-sm btn-danger p-2'>
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        } */}
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
        </div >

    </>)
}

export default AllCert;