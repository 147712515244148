import React, { useEffect, useState } from "react";
import Nav from './Nav';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteError
} from "react-router-dom";

import { StyledPageTitle } from './Styles/pages.style';
import Table from 'react-bootstrap/Table';
import { FaPlus, FaHome, FaEye, FaTrash, FaEdit, FaComments, FaSearch } from "react-icons/fa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import axios from 'axios'



import AddCenter from './AddCenter'
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

const GetAllPartners = () => {
    const user_type = JSON.parse(localStorage.getItem('admin'))
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState('');
    const [partners, setPartner] = useState(null)
    const [loader, setLoader] = useState(false)
    const [name, setName] = useState("");
    const [website, setWebsite] = useState("");
    const [image, setImage] = useState(null);


    console.log(image);
    const handleClose = () => setShow(false);

    useEffect(() => {
        let token = localStorage.getItem("token");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/allpartners`, requestOptions)
            .then(response => response.json())
            .then(result => setPartner(result.message))
            .catch(error => console.log('error', error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoader(true);

        const formData = new FormData();
        formData.append('image', image);
        formData.append('website', website);
        formData.append('name', name);



        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/addpartner`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log(response.data);
            Swal.fire({
                title: 'Partner Added Succesfully',
                icon: 'success',
                confirmButtonText: 'close'

            })
            window.location.reload();

        } catch (error) {
            console.error(error);
        }

    }

    function handleFilter(e) {
        const query = e.target.value;

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/searchpartners/${query}`, requestOptions)
            .then(response => response.json())
            .then(result => setPartner(result))
            .catch(error => console.log('error', error));
    }

    const handleDelete = (index, e, id) => {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/deletepartner/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire(
                    'Success!',
                    'Partner Deleted Successfully!',
                    'success'
                )
                window.location.reload()

            })
            .catch(error => console.log('error', error));
    }
    return (
        <StyledPageTitle>
            <div className="card-header border-bottom m-5" id="row2-card-header">
                <div className="top-sales-header">
                    <h1 className="title">Manage Partners</h1>
                    {
                        user_type.type != 'View' &&
                        <div>

                            <button className="btn btn-success btnAdd" onClick={handleShow}>
                                <FaPlus />Add New Partner
                            </button>
                        </div>
                    }


                    <div>
                        <Form.Group className="pull flex" controlId="formBasicPassword">

                            <input className="form-control" onChange={handleFilter} type="text" placeholder="--search for partners---" />

                            <a href="/partners" className="btn btn-success m-2 w-50" >
                                <FaSearch />View All Partners
                            </a>
                        </Form.Group>


                    </div>
                </div>
            </div>
            <div className="table">
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Web Url</th>
                            <th>Img Url</th>
                            <th>Date Added</th>

                            <th></th>
                            <th></th>
                            <th></th>

                        </tr>
                        {
                            Array.isArray(partners) && (
                                partners.map((row, key) => (
                                    <>

                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.web_url}</td>
                                            <td>{row.img_url}</td>
                                            <td>{row.created_at}</td>
                                            {
                                                user_type.type != 'View' &&
                                                <>
                                                    <td>
                                                        <Link
                                                            to={`/edit-partner/${row.id}`}
                                                            className="btn btn-info m-2"
                                                            title="Edit Record"
                                                        >
                                                            <FaEdit /> EDIT
                                                        </Link>
                                                    </td>
                                                    <td>

                                                        <button className="btn btn-danger m-2" onClick={e => handleDelete(key, e, row.id)}>
                                                            <FaTrash />Delete
                                                        </button>

                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    </>
                                ))
                            )
                        }


                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>

            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Partner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            loader ? <CircularProgress /> : ""
                        }
                        <form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Partner Name</Form.Label>
                                <Form.Control type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Website</Form.Label>
                                <Form.Control type="text"
                                    onChange={(e) => setWebsite(e.target.value)}
                                    value={website}
                                />
                            </Form.Group>
                            <div className="mb-3">
                                <input type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} className="form-control" />
                            </div>

                            <button type="submit" class="btn btn-success" >
                                Save Changes
                            </button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>

        </StyledPageTitle>
    );
}

export default GetAllPartners;


