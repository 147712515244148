import React, { useEffect, useState } from "react";
import axios from 'axios'
import Nav from './Nav'
import Summary from './Cards'
import Tops from "./Tops"
import FullCards from './FullCard'
import { StyledMainBody } from './Styles/MainBody.styles';
import SideNav from './SideNav'



const MainBody = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState([])

    let otp = localStorage.getItem("otp");
    let email = localStorage.getItem("email");
    let token = localStorage.getItem("token");

    if (token == null && email == null && otp == null) {
        window.location.href = "/";
    } else if (token != null && email != null && otp == null) {
        window.location.href = "/otp";
    }

    return (
        <>
            <div className="row ">
                <div className="col-1">
                    <SideNav />
                </div>
                <div className="col-11">
                    <Nav />
                    <Summary />
                    <div className="fulloverflow">
                        <Tops />

                    </div>
                    <FullCards />
                </div>
            </div>
            {/* <StyledMainBody> */}

            {/* </StyledMainBody> */}
        </>

    );
}

export default MainBody;
