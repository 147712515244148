import React, { useEffect, useState, useRef } from "react";
import { useRouteError } from "react-router-dom";


import { StyledLoginForm } from './Styles/StyledLoginForm.styles'
import { StyledOTP } from './Styles/StyledOTP.styles'
import { StyledForgotPassword } from './Styles/StyledForgotPassword'
import { StyledRememberMe } from './Styles/StyledRememberMe'


import axios from 'axios'

import Swal from "sweetalert2";

function OTPForm() {
    const [otp, setOtp] = useState([])
    const [filled, setFilled] = useState([])
    useEffect(() => {
        setOtp(filled.join(''))

    }, [filled])

    let inputArray = []
    function handleInput(e) {
        let inputVal = e.target.value;
        if (inputVal != [] && inputVal.length == 1) {
            if (e.target.nextSibling != null) {
                e.target.nextSibling.focus()
                setFilled(filled.concat(inputVal))

            } else {
                inputArray.push(inputVal)
                setFilled(filled.concat(inputVal))
                // setFilled(filled.join(''))
            }
        } else {
            if (e.target.previousSibling != null) {
                e.target.previousSibling.focus()
            } else {
                setFilled([])
            }
        }
    }

    const [isLoggedIn, setisLoggedIn] = useState(null);

    const [token, SetToken] = useState(null);

    //const otp =otp1 + otp2 + otp3 + otp4 + otp5;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            // set configurations
            const configuration = {
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/api/verifyOtp`,
                data: {
                    otp,
                    token: token,
                },

                headers: { Authorization: `Bearer ${token}` }

            };



            axios(configuration)

                .then((result) => {

                    if (result.status == 200) {

                        Swal.fire(
                            'Success!',
                            'You are login Successfully!',
                            'success'
                        )
                        localStorage.setItem("otp", otp);
                        localStorage.setItem('details', JSON.stringify(result.data))

                        if (result.data.user == 'supper') {
                            window.location.href = "/dashboard";
                        } else if (result.data.user == 'Iva') {
                            window.location.href = "/dashboardIva";
                        } else if (result.data.user == 'Women_Affairs') {
                            window.location.href = "/dashboard/women_affairs";
                        } else if (result.data.user == 'BPP') {
                            window.location.href = "/dashboard/bpp";
                        } else if (result.data.user == 'Environment') {
                            window.location.href = "/dashboard/environment";
                        }



                    } else if (result.status == 201 || result.status == 202) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.status,

                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: result.data.message,

                        })
                    }

                })
                .catch((error) => { console.log(error); })


        } catch (error) {
            console.log(error);
            // setError(error);
        }
    }

    useEffect(() => {
        let otp = localStorage.getItem("otp");
        let email = localStorage.getItem("email");
        let token = localStorage.getItem("token");

        if (token != null) {
            SetToken(token);
        }

        if (email == null || token == null) {
            window.location.href = "/";
        } else if (token != null && email != null && otp != null) {
            window.location.href = "/dashboard";
        }
    }, [])
    function resend_otp(e) {
        e.preventDefault()
        let email = localStorage.getItem("email");

        var formdata = new FormData();
        formdata.append("email", `${email}`);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/resendOtp`, requestOptions)
            .then(response => response.text())
            .then(result => {
                Swal.fire({
                    title: 'success',
                    text: 'Otp Resent',
                    timer: 1000
                })
            })
            .catch(error => console.log('error', error));
    }
    return (
        <StyledLoginForm>

            <form className='loginForm' onSubmit={handleSubmit}>

                <div className='LoginTitle'>OTP Verification</div>
                <p>
                    Electronic Platform for Performance Monitoring of Centres of Excellence Verification and
                    Reporting
                </p>

                <div className="">
                    <div className="d-flex OtpComponent">
                        <input maxLength={1} onChange={handleInput} className="" type="text" />
                        <input maxLength={1} onChange={handleInput} className="" type="text" />
                        <input maxLength={1} onChange={handleInput} className="" type="text" />
                        <input maxLength={1} onChange={handleInput} className="" type="text" />
                        <input maxLength={1} onChange={handleInput} className="" type="text" />
                    </div>
                </div>


                {/* 
                <StyledOTP>
                    <label className='loginp'>OTP</label>
                    <input
                        type="text"
                        name="SetOTP1"
                        id="SetOTP1"
                        value={otp1}
                        onChange={handleInput}
                        tabIndex="1" maxLength="1"
                    />

                    <input
                        type="text"
                        name="SetOTP2"
                        id="SetOTP2"
                        value={otp2}
                        onChange={handleInput}
                        tabIndex="2" maxLength="1"
                    />

                    <input
                        type="text"
                        name="SetOTP3"
                        id="SetOTP3"
                        value={otp3}
                        onChange={handleInput}
                        tabIndex="3" maxLength="1"
                    />


                    <input
                        type="text"
                        name="SetOTP4"
                        id="SetOTP4"
                        value={otp4}
                        onChange={handleInput}
                        tabIndex="4" maxLength="1"
                    />


                    <input
                        type="text"
                        name="SetOTP5"
                        id="SetOTP5"
                        value={otp5}
                        onChange={handleInput}
                        tabIndex="5" maxLength="1"
                    />
                </StyledOTP> */}




                <StyledRememberMe>
                    <button onClick={resend_otp}>Resend OTP?</button>
                    <button type="submit">Verify</button>
                </StyledRememberMe  >

            </form>
        </StyledLoginForm>
    );
}

export default OTPForm;