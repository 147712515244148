import React, { useState } from 'react';
import { StyledSideNav } from './Styles/SideNav.styles';
import { FaMapSigns, FaHome, FaSignOutAlt, FaCog, FaFilePrescription, FaComments, FaLeanpub, FaUsers, FaSearchengin } from "react-icons/fa";

import { Tooltip } from 'react-tooltip'
import { Assessment, CalendarMonth, CommentSharp, DashboardCustomize, Directions, GroupSharp, HandshakeSharp, Home, LocalActivity, LocationCitySharp, LogoutRounded, Note, PeopleAlt, Person2, Person2Outlined, Report, Search, Settings } from '@mui/icons-material';

const SideNav = () => {

    const [isLoggedIn, setisLoggedIn] = useState(null);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('details')))
    const logOut = () => {
        setisLoggedIn(false);
        localStorage.clear();
        window.location.href = "/";
    };


    return (
        // <StyledSideNav>
        <div className='navAdmin text-center py-5 px-4 position-sticky'>


            <div ><a href="/dashboard" title="Home" id="home" data-tooltip-content="Mome"><Person2 className='icons' /></a></div>

            <div><a href="/dashboard" title="Home" id="home" data-tooltip-content="Mome"><Home className='icons' /></a></div>
            <div><a href="/centers" title="Manage Centers"><LocationCitySharp className='icons' /></a></div>
            <div><a href="/manage-dashboards" title="Manage Dashboards"><DashboardCustomize className='icons' /></a></div>

            {/* <div><a href="/programmes" title='Manage Programmes'><Note className='icons' /></a></div> */}
            {/* <div><a href="/verification" title="Manage DLI Verification"> <Search className='icons' /></a></div>

    <div><a href="/users" title="Manage Users"><GroupSharp className='icons' /></a></div>*/}
            <div><a href="/partners" title="Manage Partners"><HandshakeSharp className='icons' /></a></div>

            <div><a href="/pdo-dates" title="PDO DATES"><CalendarMonth className="icons" /> </a></div>

            <div><a href="/pdo-reports" title="PDO SUMMARY"><Assessment className="icons" /> </a></div>

            <div><a href="/settings" title="Settings"><Settings className='icons' /></a></div>
            <div><a href="/activitylogs" title="Activity Logs"><Note className='icons' /></a></div>
            <div className='lastLi'><a href="/"><LogoutRounded className='icons' /></a></div>

            {/* <img src="https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes-thumbnail.png" alt="" />  */}
            {/* <ul>
                <li><a href="/dashboard" title="Home" id="home" data-tooltip-content="Mome"><FaHome /></a></li>
                <li><a href="/centers" title="Manage Centers"><FaMapSigns /></a></li>
                <li><a href="/programmes" title='Manage Programmes'><FaLeanpub /></a></li>
                <li><a href="/verification" title="Manage DLI Verification"><FaSearchengin /></a></li>

                <li><a href="/users" title="Manage Users"><FaUsers /></a></li>
                <li><a href="/messages" title="Manage Messages"><FaComments /></a></li>
                <li><a href="/settings" title="Operations"><FaCog /></a></li>
            </ul>
            <ul>
                <li className='lastLi'><a href="/"><FaSignOutAlt style={{ color: '#29CB97' }} /></a></li>
            </ul> */}
            {/* // </StyledSideNav> */}
        </div >
    );
}

export default SideNav;
